import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import * as Yup from "yup";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import map from "lodash/map";
import some from "lodash/some";
import * as ProgramApi from "api/ProgramApi";
import * as ProgramActions from "actions/ProgramActions";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import SignatureBox from "components/common/SignatureBox";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { handleErrorResponse } from "utils/request";
import useFormHandlers from "utils/useFormHandlers";
import { DANGER, SUCCESS } from "constants/ButtonTypes";
import { CANCELED, DECLINE, DECLINED, ELIGIBLE, ENROLL, ENROLLED } from "constants/ProgramStatuses";

const CHECKBOX = "Checkbox";

const schema = Yup.object().shape({
	acceptedTerms: Yup.bool().oneOf([true], 'Field must be checked').default(false).required(),
	formFieldsJson: Yup.array().of(
		Yup.object().shape({
			ProgramServiceName: Yup.string(),
			DoEnroll: Yup.bool().default(false),
		})
	).default([]),
	signature: Yup.string().min(2).required(),
	status: Yup.string(),
});

function ProgramForm(props) {
	const {
		clinicProgramServiceStatusUpdated,
		clinicProgramStatusUpdated,
		onAfterSave,
		onCancel,
		onClose,
		parentProgram,
		program,
	} = props;
	const { formData, handleUpdate, isValid, invalidFields } = useFormHandlers(
		{
			...program,
			acceptedTerms: false,
            formFieldsJson: [],
			signature: "",
		},
		schema,
	);
    const [loading, setLoading] = useState(false);

	const validationCheck = () => {
		const programList = [4];

		if (!parentProgram && formData.formFieldsJson.length > 0 && programList.includes(program.programId)) {
			return isValid && some(formData.formFieldsJson, field => field.DoEnroll === true);
		} else {
			return isValid;
		}
	}

    const enrollButtonIsEnabled = validationCheck();

    useEffect(() => {
        if (program.formFieldsJson) {
            let fields = get(program, "formFieldsJson") || []; // Should be an array of objects.

            if (!isArray(fields)) { // Fix for when fields is an empty object. https://greenline-pet.atlassian.net/browse/GD-3843?focusedCommentId=40579
                console.warn("formFieldsJson must be an array:", fields);
                fields = [];
            }

            handleUpdate({
                name: "formFieldsJson",
                value: [...fields],
            });
        }
    }, [program.formFieldsJson]);

	const handleUpdateStatus = async (updatedProgram) => {
		setLoading(true);
		if (!!parentProgram) {
			const programService = {
				...updatedProgram,
				programId: parentProgram,
				formFieldsJson: JSON.stringify(updatedProgram.formFieldsJson)
			};
			await ProgramApi.updateClinicProgramServiceStatus(programService)
		        .then((res) => {
		            clinicProgramServiceStatusUpdated(programService);
					if (!!onAfterSave) {
						onAfterSave();
					}
		        })
		        .catch((error) => {
					handleErrorResponse("updating clinic program service", error);
		        })
				.finally(() => {
					setLoading(false);
				});
		} else {
			const program = {
				...updatedProgram,
				formFieldsJson: JSON.stringify(updatedProgram.formFieldsJson)
			};
			await ProgramApi.updateClinicProgramStatus(program)
	            .then((res) => {
	                clinicProgramStatusUpdated(program);
					if (!!onAfterSave) {
						onAfterSave();
					}
	            })
	            .catch((error) => {
					handleErrorResponse("updating clinic program", error);
	            })
				.finally(() => {
					setLoading(false);
				});
		}
	}

	const handleDecline = () => {
		const updatedProgram = {
			...formData,
			status: DECLINE,
		};
		handleUpdateStatus(updatedProgram);
		onClose();
	};

	const handleSubmit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		const updatedProgram = {
			...formData,
			status: ENROLL,
		};
		handleUpdateStatus(updatedProgram);
		onClose();
	};

	const handleCancelClicked = () => {
		onCancel();
	}

	const handleIframeLoaded = () => {
		const iFrameID = document.getElementById(`program_iframe_${program.id}`);
        if (iFrameID) {
            // here you can make the height, I delete it first, then I make it again
            iFrameID.height = "";
            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + 40 + "px";
        }
	};

    const canEnroll = (program.status === ELIGIBLE || program.status === CANCELED || program.status === DECLINED);

	return (
		<form onSubmit={handleSubmit}>
			<iframe
				id={`program_iframe_${program.id}`}
				style={{
					width: "100%",
					flex: "none",
					border: "none",

				}}
				scrolling="no"
		        srcDoc={program?.body}
				onLoad={handleIframeLoaded}
			/>
            <div>
                {(formData.formFieldsJson || []).length > 0 ? (
                    <>
                        {map(program.optInFormConfigJson, (config, index) => {
                            const formFieldIndex = findIndex(formData.formFieldsJson, f => get(f, "ProgramServiceName") === config.ProgramServiceName);

                            if (formFieldIndex >= 0) {
                                const doEnroll = get(formData, `formFieldsJson[${formFieldIndex}].DoEnroll`, false);

                                switch (config.Type) {
                                    case CHECKBOX:
                                        return (
                                            <CheckboxInput
                                                key={index}
                                                checked={doEnroll}
                                                name={`formFieldsJson[${formFieldIndex}].DoEnroll`}
                                                onChange={handleUpdate}
                                                label={config.DisplayText}
                                                disabled={!canEnroll}
                                            />
                                        );
                                    default:
                                        return <div key={index}>TODO: Type not yet supported: {config.Type}</div>;
                                }
                            }
                        })}
                    </>
                ) : null}
			</div>
			{program.status !== ELIGIBLE && (
				<div className="text-center">
					{program.status} on {moment(program.userActionDate).format("MM/DD/YYYY")} by {program.signature}
				</div>
			)}
			{canEnroll && (
				<div className="margin-top-md">
					<SignatureBox
						onChange={handleUpdate}
						name="signature"
						value={formData.signature}
						hasError={!!includes(invalidFields, "signature")}
						label="Please Sign Here"
						autoFocus
					/>
					<CheckboxInput
						name="acceptedTerms"
						onChange={handleUpdate}
						hasError={!!includes(invalidFields, "acceptedTerms")}
						label="I accept the terms and conditions"
						checked={formData.acceptedTerms}
					/>
				</div>
			)}
			<div className="flex spaced-content border-top align-center">
				{((program.status === ENROLLED) && !!onCancel) && (
					<>
						<div className="flex-1">
							<Button
								type={DANGER}
								onClick={handleCancelClicked}
								text
							>
								Cancel Enrollment
							</Button>
						</div>
					</>
				)}
				{program.status === CANCELED && (
					<div className="flex-1"/>
				)}
				{program.status === ELIGIBLE && (
					<Button
						type={DANGER}
						onClick={handleDecline}
						disabled={!formData.signature}
					>
						Decline
					</Button>
				)}
				{canEnroll && (
					<Button
						buttonType="submit"
						type={SUCCESS}
						disabled={!enrollButtonIsEnabled}
					>
						Enroll
					</Button>
				)}
			</div>
			<SpinnerTakeover show={loading} />
		</form>
	);
}

ProgramForm.propTypes = {
	clinicProgramServiceStatusUpdated: PropTypes.func,
	clinicProgramStatusUpdated: PropTypes.func,
	onAfterSave: PropTypes.func,
	onCancel: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	parentProgram: PropTypes.number,
	program: PropTypes.object.isRequired,
};

export default connect(
	null,
	(dispatch) => ({
		clinicProgramStatusUpdated: (program) => dispatch(ProgramActions.clinicProgramStatusUpdated(program)),
		clinicProgramServiceStatusUpdated: (programService) => dispatch(ProgramActions.clinicProgramServiceStatusUpdated(programService)),
	})
)(ProgramForm);
