import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import get from "lodash/get";
import includes from "lodash/includes";
import * as styles from "./ServiceLandingPage.scss";
import * as ProgramActions from "actions/ProgramActions";
import AccessDeniedPage from "components/clinic/pages/AccessDeniedPage";
import Accordion from "components/common/Accordion";
import AccordionSection from "components/common/AccordionSection";
import Button from "components/common/Button";
import ClinicBase from "components/layout/ClinicBase";
import Image from "components/common/Image";
import ProgramOptInDetailsModal from "components/clinic/elements/ProgramOptInDetailsModal";
import { getClinicInfo } from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import {ENROLLED, INELIGIBLE} from "constants/ProgramStatuses";
import * as UserPermissions from "constants/UserPermissions";

function ServiceLandingPage(props) {
	const params = useParams();
	const {
		canEditProgramEnrollmentClinic = false,
		canViewProgramEnrollmentClinic = false,
		clinicId = Number(params.clinicId),
		getClinicProgramServiceDetails,
		getClinicProgramServices,
		getClinicPrograms,
		service,
		serviceDetails,
	} = props;
    const history = useHistory();
    const { pathname } = useLocation();
	const nodeId = Number(params.nodeId);
    const programId  = Number(get(params, "programId", null)) || null;
    const serviceId  = Number(get(params, "serviceId", null)) || null;
    const isAdmin = clinicId && includes(pathname, "/admin");
    const isProvider = nodeId && includes(pathname, "/provider");
    // If is an admin then append some string to beginning of URL
	const linkBase = isAdmin ? `/admin/clinic/${clinicId}` : isProvider ? `/provider/location/${nodeId}/clinic/${clinicId}` : '';
    const [showManageEnrollment, setShowManageEnrollment] = useState(false);
    const [emailSectionOpen, setEmailSectionOpen] = useState(false);

    useEffect(() => {
        getClinicPrograms(clinicId);
    }, [clinicId]);

    useEffect(() => {
		getClinicProgramServiceDetails(clinicId, programId, serviceId);
		getClinicProgramServices(clinicId, programId);
    }, [clinicId, params]);

	useEffect(() => {
		if (serviceDetails?.redirectUrl) {
            history.push(`${linkBase}${serviceDetails?.redirectUrl}`);
		}
    }, [serviceDetails]);

	if (!canViewProgramEnrollmentClinic) {
		return <AccessDeniedPage />;
	}

	const handleIframeLoaded = (e, id) => {
		const iFrameID = document.getElementById(id);
        if (iFrameID) {
            // here you can make the height, I delete it first, then I make it again
            iFrameID.height = "";
            iFrameID.width = "";
            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + 40 +"px";
            iFrameID.width = iFrameID.contentWindow.document.body.scrollWidth +"px";
        }
	};

	const handleOpen = () => {
		setEmailSectionOpen(!emailSectionOpen);
	}

    return (
        <ClinicBase
            clinicId={clinicId}
            docTitle={service.programServiceName || "Service"}
            // docTitle="TEST"
            pageTitle={(
                <div className="flex align-center spaced-content">
                    <div>
	                    <Image
                            src={service.programServiceImage}
                            title={service.programServiceName}
                            alt={service.programServiceName}
                            style={{
                                width: "40px",
                            }}
                        />
                    </div>
                    <div>{service.programServiceName}</div>
                </div>
            )}
        >
			<div className="padding-top-md">
			<Button
				icon
				onClick={() => history.push(`/admin/clinic/${clinicId}/programs/${programId}`)}
			>
				<i className="fa fa-arrow-left fa-fw" /> Programs
			</Button>
			</div>
            <div className={classnames(styles.root, {
                "flex spaced-content": serviceDetails?.body,
            })}>
                <div className={styles.programDetails}>
                    <h2 className="text-center">{serviceDetails?.title}</h2>
                    <div className={styles.status}>{service?.status}</div>
                    {/*<div dangerouslySetInnerHTML={{ __html: serviceDetails?.body }} />*/}
	                {!!serviceDetails?.body && (
		                <iframe
							id={`service_iframe_${serviceDetails.programServiceId}`}
							style={{
								width: "100%",
								flex: "none",
								border: "none",
							}}
							scrolling="no"
					        srcDoc={serviceDetails.body}
							onLoad={(e) => handleIframeLoaded(e, `service_iframe_${serviceDetails.programServiceId}`)}
						/>
	                )}
	                {serviceDetails?.expandableContent && (
						<div className="margin-bottom-sm">
			                <Accordion >
				                <AccordionSection
					                id="email_example"
					                onClick={handleOpen}
					                header={serviceDetails?.expandableContentName ? (
										<div dangerouslySetInnerHTML={{ __html: serviceDetails.expandableContentName }}/>
					                ) : ""}
				                >
					                <div className="flex">
						                <div className={styles.emailExample}>
							                {serviceDetails?.expandableContentHeader && (
												<div
													className="border-bottom"
													dangerouslySetInnerHTML={{ __html: serviceDetails.expandableContentHeader }}
												/>
							                )}
							                <div className="flex flex-centered">
												<iframe
													id={`service_expandable_iframe_${serviceDetails.programServiceId}`}
													style={{
														maxWidth: "100%",
														flex: "none",
														border: "none",
													}}
													scrolling="no"
											        srcDoc={serviceDetails.expandableContent}
													onLoad={(e) => handleIframeLoaded(e, `service_expandable_iframe_${serviceDetails.programServiceId}`)}
												/>
							                </div>
						                </div>
					                </div>
				                </AccordionSection>
			                </Accordion>
						</div>
	                )}
                    <div className="text-center">Activated on {moment(service.userActionDate).format("MM/DD/YYYY")} by {service.signature}</div>
	                {canEditProgramEnrollmentClinic && (
	                    <div className="flex flex-centered padding-top-md">
	                        <Button
		                        onClick={() => setShowManageEnrollment(true)}
		                        disabled={(props.service.status === INELIGIBLE && !props.service.acceptedTerms)}
	                        >
	                            Manage Enrollment
							</Button>
	                    </div>
	                )}
                </div>
            </div>
            <ProgramOptInDetailsModal
                key={`Program_service_${clinicId}_${programId}_${serviceId}`}
                clinicId={clinicId}
                onClose={() => setShowManageEnrollment(false)}
                program={service}
                programId={serviceId}
                parentProgram={programId}
                show={showManageEnrollment}
				linkBase={linkBase}
            />
        </ClinicBase>
    )
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        let programId = get(ownProps.match.params, "programId", null);
        programId = programId ? Number(programId) : null;
		let serviceId = get(ownProps.match.params, "serviceId", null);
        serviceId = serviceId ? Number(serviceId) : null;
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const clinicProgramServiceList = get(state.entities.clinicProgramServiceDetails, clinicId, {});
        const clinicProgramServiceDetails = get(clinicProgramServiceList, programId, {});
        const programs = get(state.entities.clinicProgramServices, clinicId, {});
        const programServices = get(programs, programId, {});
		const userProfile = state.user.userProfile;
        const canEditProgramEnrollmentClinic = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        const canViewProgramEnrollmentClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        return {
	        clinic,
            clinicId,
	        serviceDetails: get(clinicProgramServiceDetails, serviceId, null),
	        service: get(programServices, serviceId, {}),
	        canEditProgramEnrollmentClinic,
	        canViewProgramEnrollmentClinic,
        };
    },
	(dispatch) => ({
		getClinicPrograms: (clinicId) => dispatch(ProgramActions.loadClinicPrograms(clinicId)),
		getClinicProgramServiceDetails: (clinicId, programId, serviceId) => dispatch(ProgramActions.loadClinicProgramServiceDetails(clinicId, programId, serviceId)),
		getClinicProgramServices: (clinicId, programId) => dispatch(ProgramActions.loadClinicProgramServices(clinicId, programId)),
	})
)(ServiceLandingPage);
