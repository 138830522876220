import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import * as styles from "./EligibilityTrackerInstructionsPage.scss";
import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import { getEligibilityTrackerQRCode } from "api/ClinicApi";
import * as ProgramActions from "actions/ProgramActions";
import Button from "components/common/Button";
import ClinicBase from "components/layout/ClinicBase";
import Image from "components/common/Image";
import ProgramTile from "components/programs/ProgramTile";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { getClinicInfo } from "utils/ClinicData";
import logger from "utils/logger";
import { downloadFile, imageFile } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import ProgramOptInDetailsModal from "components/clinic/elements/ProgramOptInDetailsModal";
import { CANCELED, DECLINED, ELIGIBLE, ENROLLED, INELIGIBLE } from "constants/ProgramStatuses";
import * as UserPermissions from "constants/UserPermissions";

function EligibilityTrackerInstructionsPage(props) {
    const {
        basePath = "",
        canEditProgramEnrollmentClinic = false,
        clinicId,
        hasEligibilityTracker = false,
        programServices,
        service,
        serviceDetails,
    } = props;
    const history = useHistory();
    const params = useParams();
    const { pathname } = useLocation();
    const programId  = 7;
    const serviceId  = 10;
    const nodeId = Number(params.nodeId);
    const isAdmin = clinicId && includes(pathname, "/admin");
    const isProvider = nodeId && includes(pathname, "/provider");
    const linkBase = isAdmin ? `/admin/clinic/${clinicId}` : isProvider ? `/provider/location/${nodeId}/clinic/${clinicId}` : '';
    const [qrCodeRes, setQRCodeRes] = useState(null);
    const [qrCode, setQRCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showManageEnrollment, setShowManageEnrollment] = useState(false);

    const handleLoadEligibilityTrackerQRCode = () => {
        setLoading(true);
        getEligibilityTrackerQRCode(clinicId)
            .then((res) => {
                setQRCodeRes(res);
                const url = imageFile(res, "jpg", true);
                setQRCode(url);
            })
            .catch((err) => {
                logger.error("Error loading Eligibility Tracker QR Code:", err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (hasEligibilityTracker) {
            handleLoadEligibilityTrackerQRCode();
        }
    }, [hasEligibilityTracker]);

    useEffect(() => {
		props.getClinicProgramServiceDetails(props.clinicId, programId, serviceId);
		props.getClinicProgramServices(props.clinicId, programId);
    }, [props.clinicId, params]);

    const handleManageEnrollment = () => {
        setShowManageEnrollment(true);
    };

    const handleSearchEligibleClients = () => {
        history.push(`${basePath}eligible-clients`);
    };

    const handleDownloadQRCode = () => {
        downloadFile(qrCodeRes, "jpeg");
    };

    return (
        <ClinicBase
            clinicId={clinicId}
            docTitle="Eligibility Tracker"
            pageTitle={(
                <div className="flex align-center spaced-content">
                    <div>
	                    <Image
                            src={service.programServiceImage}
                            title={service.programServiceName}
                            alt={service.programServiceName}
                            style={{
                                width: "40px",
                            }}
                        />
                    </div>
                    <div>{service.programServiceName}</div>
                </div>
            )}
        >
            <div className={classnames(styles.root, "spaced-content")}>
                <div className={styles.serviceList}>
                    <div className="padding-bottom-md">
                        <Button
                            icon
                            onClick={() => history.push(`/admin/clinic/${clinicId}/programs/${programId}`)}
                        >
                            <i className="fa fa-arrow-left fa-fw" /> Eligibility Tracker
                        </Button>
                    </div>
                    {map(orderBy(programServices, [p => {
                        if (p.status === ELIGIBLE) return 0;
                        else if (p.status === ENROLLED) return 1;
                        else if (p.status === DECLINED) return 2;
                        else if (p.status === CANCELED) return 3;
						else if (p.status === INELIGIBLE) return 4;
                        else return 5;
                    }, "programServiceName"]), service => (
                        <ProgramTile
                            key={service.programServiceId}
                            canEditProgramEnrollmentClinic={canEditProgramEnrollmentClinic}
                            clinicId={clinicId}
                            imageStyle={{
	                            display: "flex",
	                            margin: "auto",
								maxWidth: "100%",
	                            width: "auto",
							}}
                            parentProgram={programId}
                            style={{
                                width: "216px",
                                border: "2px solid rgb(120, 179, 58)",
                                borderRadius: "6px",
                            }}
                            tile={{
                                ...service,
                                id: service.programServiceId,
                                image: service.programServiceImage,
                                name: service.programServiceName,
                                startDate: service.programServiceStartDate,
                                endDate: service.programServiceEndDate,
                            }}
                        />
                    ))}
                </div>
                <div className={classnames("flex flex-1 flex-column padding-top-md", {
                    "spaced-content": serviceDetails?.body,
                })}>
                    <h2>Greenline Eligibility Tracker (Personalized Offers)</h2>

                    <div className="padding-top-md padding-bottom-xlg">
                        Congratulations, you have completed the setup for Greenline Eligibility Tracker!
                        You may begin using your QR code and unique instant rebate code for this program.
                        If you have any questions please reach out to Greenline support at <a
                            className="text-primary"
                            href="tel:18666089038"
                        >+1 (866) 608-9038</a>, email at <a
                            className="text-primary"
                            href="mailto:support@greenlinepet.com"
                        >support@greenlinepet.com</a>, or via live chat below!
                    </div>

                    <div className="flex flex-wrap spaced-content spaced-content-vertical">
                        {canEditProgramEnrollmentClinic && (
                            <div
                                className={styles.tile}
                                style={{
                                    maxWidth: "300px",
                                    minWidth: "300px",
                                }}
                            >
                                <div className="flex flex-column flex-1 height-100-perc">
                                    <h3 className="flex-none">Enrolled</h3>
                                    <div className="flex-none">Activated on {moment(service.userActionDate).format("MM/DD/YYYY")} by {service.signature}</div>
                                    <div className="flex-1 padding-bottom-lg" />
                                    <div className="flex-none flex flex-centered no-wrap">
                                        <Button
                                            large
                                            onClick={handleManageEnrollment}
                                        >
                                            Manage Enrollment
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div
                            className={styles.tile}
                            style={{
                                maxWidth: "300px",
                                minWidth: "300px",
                            }}
                        >
                            <div className="flex flex-column flex-1 height-100-perc">
                                <h3 className="flex-none">Eligible Clients</h3>
                                <div className="flex-none">Search by client name to view their eligibility status</div>
                                <div className="flex-1 padding-bottom-lg" />
                                <div className="flex-none flex flex-centered no-wrap">
                                    <Button
                                        large
                                        onClick={handleSearchEligibleClients}
                                    >
                                        Search Eligible Clients
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {hasEligibilityTracker && (
                            <div
                                className={classnames(styles.tile, "flex margin-bottom-sm spaced-content")}
                                style={{
                                    flex: "2 2 0",
                                    maxWidth: "525px",
                                    minWidth: "460px",
                                }}
                            >
                                <div className="flex flex-column flex-1 height-100-perc">
                                    <h3 className="flex-none">Clinic QR Code</h3>
                                    <div className="flex-none">Download the QR code to put in your clinic!</div>
                                    <div className="flex-1 padding-bottom-lg" />
                                    <div className="flex-none flex flex-centered no-wrap">
                                        <Button
                                            icon
                                            large
                                            onClick={handleDownloadQRCode}
                                        >
                                            <i className="fa fa-download" /> Download QR Code
                                        </Button>
                                    </div>
                                </div>
                                {!!qrCode && (
                                    <div className="flex-none">
                                        <Image
                                            className={styles.qrCodeImage}
                                            src={qrCode}
                                            alt="Clinic Eligibility Tracker QR code"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <ProgramOptInDetailsModal
                    key={`Program_service_${clinicId}_${programId}_${serviceId}`}
                    clinicId={clinicId}
                    onClose={() => setShowManageEnrollment(false)}
                    program={service}
                    programId={serviceId}
                    parentProgram={programId}
                    show={showManageEnrollment}
                	linkBase={linkBase}
                />
            </div>
            <SpinnerTakeover show={loading} />
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const programId = 7;
        const serviceId = 10;
        const basePath = ownProps.match.url.replace("/instructions", "/");
        const clinicProgramServiceList = get(state.entities.clinicProgramServiceDetails, clinicId, {});
        const clinicProgramServiceDetails = get(clinicProgramServiceList, programId, {});
        const programs = get(state.entities.clinicProgramServices, clinicId, {});
        const programServices = get(programs, programId, {});
        const userProfile = state.user.userProfile;
        const canEditProgramEnrollmentClinic = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);

        return {
            canEditProgramEnrollmentClinic,
            clinicId,
            basePath,
            hasEligibilityTracker: !!filter(state.entities.clinicPrograms?.[clinicId], { programId: programId, status: ENROLLED })?.length || false,
            programServices,
            serviceDetails: get(clinicProgramServiceDetails, serviceId, null),
	        service: get(programServices, serviceId, {}),
        }
    },
    (dispatch) => ({
        getClinicProgramServiceDetails: (clinicId, programId, serviceId) => dispatch(ProgramActions.loadClinicProgramServiceDetails(clinicId, programId, serviceId)),
		getClinicProgramServices: (clinicId, programId) => dispatch(ProgramActions.loadClinicProgramServices(clinicId, programId)),
    })
)(EligibilityTrackerInstructionsPage);
