import React from "react";
import PropTypes from "prop-types";
import find from "lodash/find";
import get from "lodash/get";
import toLower from "lodash/toLower";
import GenericGameCard from "components/games/elements/GenericGameCard";
import { PuppyAndKittyBowlLegalWording } from "components/games/elements/LegalWording";
import footballField from "./field.svg";
const KittyMoving = require("./kitty_running.svg");
const KittyPlaying = require("./kitty_playing.svg");
const KittySleeping = require("./kitty_sleeping.svg");
const KittyStretch = require("./kitty_stretch.svg");
const PuppyDancing = require("./puppy_dancing.svg");
const PuppyMoving = require("./puppy_running.svg");
const PuppyPlaying = require("./puppy_playing.svg");
const smallImage = "https://glprodcdnst.blob.core.windows.net/games/assets/petsbowl/logos/Clinic_View_Pets_Bowl_Banner_Image.jpg";

const SECOND = 1000;

export default function PuppyBowl2Card(props) {
    const {
        clinicId = null,
        dashboard = false,
        game = {},
        nodeId = null,
        snapshot = false,
        userId = null,
    } = props;

    const handleHasScored = (gameDetails, snapshots) => {
        let today;
        let yesterday;
        if (snapshots?.length === 1) {
            // Handle when it's the first day, all 0s for Yesterday
            yesterday = snapshots[0];
            today = find(gameDetails.data.Clinics, clinic => clinic.ClinicId === clinicId);
        } else if (snapshots?.length) {
            yesterday = find(snapshots[0].data.Clinics, clinic => clinic.ClinicId === clinicId);
            today = find(snapshots[1].data.Clinics, clinic => clinic.ClinicId === clinicId);
        } else {
            return false;
        }
        const todayTotalDoses = get(today, "CurrentPeriod.TotalReportDoses", 0);
        const yesterdayTotalDoses = get(yesterday, "CurrentPeriod.TotalReportDoses", 0);
        const newDoses = todayTotalDoses - yesterdayTotalDoses;
        return newDoses > 0;
    };

    const handleHasNotScored = (gameDetails, snapshots) => {
        return !handleHasScored(gameDetails, snapshots);
    };

    const animationOptions = [{
        id: "puppy",
        animal: "Puppy",
        animations: [{
            // Run
            altText: "Puppy Running",
            animationDuration: 3 * SECOND,
            canView: true,
            duration: SECOND / 3,
            frames: [{
                top: "0",
                left: "0",
            }, {
                top: "0",
                right: "-0.35em",
            }],
            height: "10em",
            image: PuppyMoving,
            isAnimated: true,
            lastFrame: 1,
            left: "50%",
            right: "50%",
            showScore: false,
            width: "14em",
        }, {
            // Dance
            altText: "Puppy Dancing",
            animationDuration: 5 * SECOND,
            duration: SECOND / 2,
            frames: [{
                top: "0",
                left: "0",
            }, {
                top: "0",
                right: "-0.01em",
            }],
            getCanView: handleHasScored,
            height: "10em",
            image: PuppyDancing,
            isAnimated: true,
            lastFrame: 1,
            left: "50%",
            right: "50%",
            showScore: true,
            width: "9em",
        }, {
            // Playing
            altText: "Puppy Playing",
            animationDuration: 5 * SECOND,
            duration: SECOND / 2,
            frames: [{
                top: "0",
                left: ".7em",
            }, {
                top: "0",
                right: "0",
            }],
            getCanView: handleHasNotScored,
            height: "10em",
            image: PuppyPlaying,
            isAnimated: true,
            lastFrame: 1,
            left: "50%",
            right: "50%",
            showScore: true,
            width: "11em",
        }]
    }, {
        id: "kitty",
        animal: "Kitty",
        animations: [{
            // Run
            altText: "Kitty Running",
            animationDuration: 2.5 * SECOND,
            canView: true,
            duration: SECOND / 3,
            frames: [{
                top: "0",
                left: "0",
            }, {
                top: "0",
                right: "13px",
            }],
            height: "7em",
            image: KittyMoving,
            isAnimated: true,
            lastFrame: 1,
            left: "50%",
            right: "50%",
            showScore: false,
            width: "11em",
        }, {
            // Stretch
            altText: "Kitty Stretching",
            animationDuration: SECOND / 2,
            duration: SECOND / 2,
            frames: [{
                top: "0",
                left: "0",
                duration: 3 * (SECOND / 8),
            }, {
                top: "0",
                right: "-4px",
            }],
            getCanView: handleHasScored,
            height: "7em",
            image: KittyStretch,
            isAnimated: true,
            lastFrame: 1,
            left: "53.5%",
            right: "50%",
            showScore: true,
            width: "9em",
        }, {
            // Sleep
            altText: "Kitty Sleeping",
            animationDuration: 4 * SECOND,
            duration: SECOND / 2,
            frames: [{
                top: "0",
                left: "0",
            }, {
                top: "0",
                right: "-0.04em",
            }],
            getCanView: handleHasScored,
            height: "7em",
            image: KittySleeping,
            isAnimated: true,
            lastFrame: 0,
            left: "50%",
            right: "50%",
            showScore: true,
            width: "11em",
        }, {
            // Playing
            altText: "Kitty Playing",
            animationDuration: 5 * SECOND,
            duration: SECOND / 2,
            frames: [{
                top: "0",
                left: "0",
            }, {
                top: "0",
                right: "4%",
            }, {
                top: "0",
                right: "-103%",
            }],
            getCanView: handleHasNotScored,
            height: "7em",
            image: KittyPlaying,
            isAnimated: true,
            lastFrame: 1,
            left: "50%",
            right: "50%",
            showScore: true,
            width: "11em",
        }]
    }];

    const handleGetAnimationIndex = (gameData) => {
        if (gameData?.details && clinicId) {
            const clinicDetails = find(gameData.details, { ClinicId: clinicId });
            if (toLower(clinicDetails?.AvatarType) === "cat" || toLower(clinicDetails?.avatarType) === "cat") {
                return 1
            } else return 0;
        }
        return 0;
    };

    return (
        <GenericGameCard
            animationContainerStyles={{ right: "25%" }}
            animationOptions={animationOptions}
            backgroundImage={footballField}
            backgroundImageAltText="Football field"
            bannerImage={smallImage}
            bannerImageAltText="TEST"
            clinicId={clinicId}
            dashboard={dashboard}
            game={game}
            nodeId={nodeId}
            snapshot={snapshot}
            getAnimationIndex={handleGetAnimationIndex}
            LegalWording={PuppyAndKittyBowlLegalWording}
            userId={userId}
        />
    );
}

PuppyBowl2Card.propTypes = {
    clinicId: PropTypes.number,
    dashboard: PropTypes.bool,
    game: PropTypes.object,
    nodeId: PropTypes.number,
    snapshot: PropTypes.bool,
    userId: PropTypes.number,
};
