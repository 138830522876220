import React, {useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./DashboardTile.scss";
import DashboardTileDetails from "components/dashboard/widgets/DashboardTileDetails";
import InformationBadge from "components/common/InformationBadge";
import { addCommasToNumber } from "utils/numeric";

export default function DashboardTile(props) {
    const [expanded, setExpanded] = useState(false);

    const expandContent = () => {
        if(props.type !== "add_button") {
            setExpanded(true);
        }
    }

    const closeContent = () => {
        setExpanded(false);
    }

    const handleClick = () => {
        if(!props.disabled) {
            if (props.onClick) {
                props.onClick();
            } else if (!props.isButton) {
                setExpanded(true);
            }
        }
    }

    // Add commas and dollar sign, if needed.
    const fancify = (value) => {
        const numericPart = addCommasToNumber(value);
        return props.type && props.type === "Currency" ? `$${numericPart}` : numericPart;
    }

    return (
        <div className={classnames(styles.root, {
            [styles.dashboardButton]: props.isButton,
            [styles.disabled]: props.disabled,
        })}
            title={props.disabled ? `${props.title} disabled` : props.title}
        >
            <div
                className={classnames(styles.innerTile, {
                    [styles.clickable]: (props.isButton || props.hasDataSet),
                })}
                onClick={(props.isButton || props.hasDataSet) ? handleClick : () => {}}
            >
                {props.title &&
                    <header className={styles.header}>
                        <div className={styles.headerInner}>
                            <div className={styles.headerTitle}>{props.title}</div>
                            {props.badge && (
                                <InformationBadge message={props.badge} />
                            )}
                        </div>
                    </header>
                }
                <div className={styles.content}>
                    {props.largeValue !== undefined && <span className={styles.largeValue}>{fancify(props.largeValue)}</span>}
                    {(props.label && !props.labelBottom) && <span className={styles.label}>{props.label}</span>}
                    {props.largeIcon && <span className={styles.largeIcon}><i className={props.largeIcon} /></span>}
                    {(props.label && props.labelBottom) && <span className={styles.label}>{props.label}</span>}
                </div>
            </div>
            {(!props.isButton && props.hasDataSet) &&
                <DashboardTileDetails
                    title={props.title}
                    type={props.type}
                    onClose={closeContent}
                    expanded={expanded}
                    timeRange={props.timeRange}
                />
            }
        </div>
    );
}

DashboardTile.propTypes = {
    badge: PropTypes.any,
    label: PropTypes.string,
    largeIcon: PropTypes.string,
    largeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    timeRange: PropTypes.string,
    isButton: PropTypes.bool,
    hasDataSet: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(["Currency", "Number", undefined]),
    disabled: PropTypes.bool
};
