/**********
 * This was originally a copy from @swordsweeper/react-forms
 * Moved into this project for easier updating
 * This should be propagated back to the main repository as functions are refined
 */

import { useState, useEffect } from "react";
import { default as setValue } from "lodash/set";
import produce from "immer";
import map from "lodash/map";
import filter from "lodash/filter";
import has from "lodash/has";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import isArray from "lodash/isArray"
import forEach from "lodash/forEach";

export default function useFormHandlers(initialFormState={}, yupSchema, initialInvalidFields=[], options={}, changeRequired=true) {
    const [formData, _setFormData] = useState(initialFormState || {});
    const [changedData, _setChangedData] = useState({});
    const [invalidFields, _setInvalidFields] = useState(initialInvalidFields);
    const [isValid, _setIsValid] = useState(false);
    const [hasChanges, _setHasChanges] = useState(false);

    const checkIsValid = (newData) => {
        if (yupSchema) {
            _setIsValid(false);
            yupSchema.validate(newData, { abortEarly: false })
                .then((value) => {
                    _setInvalidFields([]);
                    _setIsValid(true);
                })
                .catch((err) => {
                    console.log(err, "ERRORS");
                    const invFields = map(err.inner, (inner) => inner.path);
                    _setInvalidFields(filter(invFields, (field) => has(newData, field)));
                    _setIsValid(false);
                });
        } else {
            _setIsValid(true);
        }
    }

    useEffect(() => {
        let source = formData;
        let target = initialFormState;
        if (options.keysToTrack) {
            source = pick(source, options.keysToTrack);
            target = pick(target, options.keysToTrack);
        }
        const changed = !isEqual(source, target);
        _setHasChanges(changed);
    }, [initialFormState]);

    useEffect(() => {
        if (!changeRequired) {
            checkIsValid(initialFormState);
        }
    }, []);

    const handleSetFormData = (newData) => {
        _setFormData(newData);
        _setHasChanges(true);
        checkIsValid(newData);
    };

    const handleUpdate = (update) => {
        let updates;
        if (isArray(update)) {
            updates = update;
        } else {
            updates = [update];
        }
        // maintain a copy of JUST the changes made through handleUpdate
        _setChangedData(produce(changedData, (draftChangedData) => {
            forEach(updates, ({ name, value, setToInitialState=false }) => {
                if (setToInitialState) {
                    // Remove the change from state if the value is being set back to its initial state
                    delete draftChangedData[name];
                } else {
                    setValue(draftChangedData, name, value);
                }
            });
            return draftChangedData;
        }));
        const newData = produce(formData, (draftFormData) => {
            forEach(updates, ({ name, value, setToInitialState=false }) => {
                if (setToInitialState) {
                    // Set the field back to its initial value
                    setValue(draftFormData, name, initialFormState[name]);
                } else {
                    setValue(draftFormData, name, value);
                }
            });
            return draftFormData;
        });
        handleSetFormData(newData);
    };

    return {
        formData,
        handleUpdate,
        changedData,
        setFormData: handleSetFormData,
        isValid,
        invalidFields,
        hasChanges,
    };
}
