import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import produce from "immer";
import * as Yup from "yup";
import find from "lodash/find";
import map from "lodash/map";
import NotificationButtons from "components/notifications/elements/NotificationButtons";
import TextBox from "components/common/TextBox";
import UserInitials from "components/notifications/elements/UserInitials";
import useFormHandlers from "utils/useFormHandlers";

function SurveyNotification(props) {
    const [initials, setInitials] = useState(null);
    const longDescription = JSON.parse(props.notificationDetails?.longDescription || "")
    const questions = longDescription?.questions || [];
    const initialData = map(questions, question => {
        return {
            answer: "",
            question: question.text,
        }
    })

    const schema = Yup.array().of(Yup.object().shape({
        answer: Yup.string().nullable(true),
        question: Yup.string().required(true),
    }));
	const {formData, setFormData } = useFormHandlers(initialData, schema);

    const handleChange = ({ name, value }, index) => {
        const newData = produce(formData, draft => {
            draft[index].answer = value;
        })
        setFormData(newData);
    };

    return (
        <div>
	        {map(questions, (question, index) => {
                const data = find(formData, { question: question.text })
				switch (question.type) {
					case "textbox":
						return (
							<TextBox
                                key={`question${index}`}
								onChange={(data) => handleChange(data, index)}
								name={`q${index}`}
								label={`${index + 1}. ${question.text}`}
                                value={data.answer}
							/>
						);
					default:
						return null;
				}
	        })}
            {props.notificationDetails.requiresInitials && (
                <div className="flex">
                    <div className="flex-1">
                        <UserInitials
                            onChange={({ value }) => setInitials(value)}
                            value={initials}
                        />
                    </div>
                    <div className="flex-1" />
                </div>
            )}
            <hr className="margin-top-sm"/>
            <div className="margin-top-md">
                <NotificationButtons
                    notificationDetails={props.notificationDetails}
                    onEnroll={props.onEnroll}
                    onDecline={props.onDecline}
                    onCancel={props.onCancel}
                    onDismiss={props.onDismiss}
                    enrollDisabled={props.notificationDetails?.requiresInitials && !initials?.length}
                    initials={initials}
                    formFieldsJson={formData}
                    isPreview={props.isPreview}
                />
            </div>
        </div>
    );
}

SurveyNotification.propTypes = {
    clinicId: PropTypes.number,
    providerId: PropTypes.number,
    notificationDetails: PropTypes.object.isRequired,
    onEnroll: PropTypes.func.isRequired,
    onDecline: PropTypes.func,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    onDismiss: PropTypes.func,
    enrollmentState: PropTypes.string,
    isPreview: PropTypes.bool
};

export default connect(
    null,
)(SurveyNotification);
