import React, { useRef, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import styles from "./EditGame.scss";
import cloneDeep from "lodash/cloneDeep";
import difference from "lodash/difference";
import filter from "lodash/filter";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import intersection from "lodash/intersection";
import map from "lodash/map";
import mergeWith from "lodash/mergeWith";
import trim from "lodash/trim";
import uniq from "lodash/uniq";
import get from "lodash/get";
import * as ProviderApi from "api/ProviderApi";
import * as ProviderActions from "actions/ProviderActions";
import * as NotificationActions from "actions/NotificationsActions";
import BiProductDisclosures from "components/common/BiProductDisclosures";
import Button from "components/common/Button";
import GameCalculations from "components/provider/widgets/GameCalculations";
import GameDetailsForm from "components/provider/widgets/GameDetailsForm";
import GameParasiticideProductsTable from "components/provider/widgets/GameParasiticideProductsTable";
import GameStartNotification from "components/notifications/forms/GameStartNotification";
import Modal from "components/common/Modal";
import GameTaskEditor from "components/games/widgets/GameTaskEditor";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import getNameForGameType from "utils/getNameForGameType";
import logger from "utils/logger";
import * as ProviderLinks from "utils/ProviderLinks";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import { getSearchParam } from "utils/windowUtils";
import { getIsChallengeMatchGame } from "utils/getIsChallengeMatchGame";
import getProviderId from "utils/getProviderId";
import { EMPTY_GROWTH_DOSES, EMPTY_CHALLENGE_MATCH, EMPTY_ON_GARD } from "constants/EmptyGames";
import { GAME_TYPES, MUTLI_CLINIC_GAMES } from "constants/GameTypes";
import { GAMES_DISCLAIMER } from "constants/Notifications";
import { SAVED } from "constants/ProviderGameStateIds";
import * as UserPermissions from "constants/UserPermissions";
import {
    mapGameDataFromServer,
    mapGameTemplateDataFromServer,
    mapOnGardReviewDataFromServerToApp,
    mapRoiDataFromServerToApp
} from "data/serverMapping";

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
function EditGame(props) {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [gameData, setGameData] = useState({});
    const [formData, setFormData] = useState({});

    const nodeId = Number(params?.nodeId) || null;
    const providerId = getProviderId(params, props.userProfile);
    const userId = Number(getSearchParam(location.search, "userId")) || props.userProfile?.userId || props.userProfile?.id || null;

    const isAdmin = useMemo(() => (
        !!(includes(location?.pathname, "/admin") && nodeId && providerId)
    ), [location, nodeId, providerId]);
    const isDemo = useMemo(() => (
        !!includes(location?.pathname, "/demo") && props.canViewClinicBIGamesDashboard
    ), [location, props.canViewClinicBIGamesDashboard]);
    const isGrowthGame = useMemo(() => (
        gameData?.gameType === GAME_TYPES.GROWTH || gameData?.gameType === GAME_TYPES.GROWTH_NGP
    ), [gameData]);

    const [reviewData, setReviewData] = useState(null);
    const [onGardReviewData, setOnGardReviewData] = useState(null);
    const [reassign, setReassign] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [saveData, setSaveData] = useState(null);
    const isSaved = !props.isUserTemplate && gameData.gameState === SAVED;
    const canEdit = (
        (props.canEditProviderBiGames || (props.canAdminProviderBiGames && props.canEditGamesAdmin)) &&
        !(props.isGame && (isAdmin ? gameData.hasCompleted : gameData.hasStarted))
    );
    let TODAY = new moment();
    let TOMORROW = TODAY.add(1, "day").format("MM/DD/YYYY");
    const startDate = new moment(gameData?.startDate);
    const startDatePlus10 = new moment(gameData?.startDate).add(10, 'days');
    const canDelete = !isDemo && (
        props.canEditGamesAdmin &&
        props.isGame &&
        !(gameData?.hasCompleted) &&
        (isAdmin ? (
            // In isBetween, "day" means we are comparing down to the date instead of the millisecond
            // In isBetween, "[)" means startDate >= today < (startDate + 10)
            !gameData?.hasStarted || !!TODAY.isBetween(startDate, startDatePlus10, 'day', '[)')
        ) : !gameData?.hasStarted)
    );
    const clinicSoldProductsDuringThePeriod = find(reviewData?.data, d => includes())

    const custom = (objValue, srcValue) => {
        if (_.isArray(objValue)) {
            return srcValue;
        }
    }
    const combinedData = cloneDeep(gameData); // Deep clone of data so the combined data doesn't reflect any changes until they're saved to game data and reloaded
    mergeWith(combinedData, formData, custom);

    const gameOrTemplate = !!combinedData.isTemplate ? "Template" : "Game"; // Will always evaluate to "Game" for puppy bowl
    const getGame = () => {
        ProviderApi.getGameById(props.id, userId, isDemo)
            .then(res => {
                setGameData(mapGameDataFromServer(res.body.result));
            })
            .catch(error => {
                handleErrorResponse(`loading ${gameOrTemplate} Details`, error, isAdmin);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getCanTransferGame = () => {
        if (props.canAdminProviderBiGames) {
            setLoading(true);
            ProviderApi.getReassignmentStatus(props.id)
                .then(res => {
                    if (!!res?.body?.canReassign) {
                        setReassign(res?.body);
                    } else {
                        setReassign(null);
                    }
                })
                .catch(error => {
                    // handleErrorResponse(`getting reassignment status`, error, isAdmin);
                    setReassign(null);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }
    const getGameCopy = () => {
        ProviderApi.copyGameById(props.id, userId)
            .then(res => {
                setGameData(mapGameDataFromServer(res.body));
            })
            .catch(error => {
                handleErrorResponse(`loading ${gameOrTemplate} Copy`, error, isAdmin);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleLoadTemplate = () => {
        ProviderApi.getTemplateById(props.id, userId) // Puppy bowl and pool party are looked up according to the greenlineGameTemplateId, if the user already has a pool party game with that template then they'll get the game data
            .then(res => {
                setGameData({
                    ...mapGameTemplateDataFromServer(res.body), // Attaches the owner's user ID and specifies that the game cannot be used as a template when setting game data
                    ownerUserId: userId,
                });
            })
            .catch(error => {
                handleErrorResponse(`loading Template Details`, error, isAdmin);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const getTemplateById = () => {
        if (props.isUserTemplate) {
            getGame();
        } else {
            handleLoadTemplate();
        }
    }
    const getTemplate = () => {
        switch(props.id) {
            case "growth-doses":
                setGameData({
                    ...EMPTY_GROWTH_DOSES,
                    ownerUserId: userId,
                    providerId,
                });
                setLoading(false);
                break;
            case "challenge-match":
                setGameData({
                    ...EMPTY_CHALLENGE_MATCH,
                    ownerUserId: userId,
                    providerId,
                });
                setLoading(false);
                break;
            case "on-gard":
                setGameData({
                    ...EMPTY_ON_GARD,
                    ownerUserId: userId,
                    providerId,
                });
                setLoading(false);
                break;
            default:
                getTemplateById();
        }
    }
    useEffect(() => {
        if (isDemo) {
            props.getBiGamesDemoClinics();
        } else {
            props.getBiGamesClinics(userId);
        }
        props.getAllNotifications();
    }, []);

    const handleLoadDemoReviewData = () => {
        ProviderApi.getDemoReviewData()
            .then(res => {
                const clinicIds = flatMap(combinedData?.clinics, "clinicId");
                const data = {
                    ...res.body,
                    gamesReviewDataAggregateVM: {
                        ...res.body.gamesReviewDataAggregateVM,
                        data: filter(res.body.gamesReviewDataAggregateVM.data, i => {
                            return includes(clinicIds, i.clinicId);
                        }),
                    },
                }
                setReviewData(mapRoiDataFromServerToApp(data, combinedData.clinics));
                setLoading(false);
            })
            .catch(error => {
                handleErrorResponse(`loading review data`, error, isAdmin);
                setLoading(false);
            });
    }

    const handleLoadClinicReviewData = () => {
        const isMultiClinic = MUTLI_CLINIC_GAMES[combinedData.gameType];
        const clinicList = isMultiClinic ? combinedData.clinics : [];
        ProviderApi.getClinicReviewData(userId, combinedData, isMultiClinic)
            .then(res => {
                setReviewData(mapRoiDataFromServerToApp(res.body, clinicList));
                setLoading(false);
            })
            .catch(error => {
                handleErrorResponse("loading clinic(s) review data", error, isAdmin);
                setLoading(false);
            });
    }
    const handleLoadOnGardReviewData = () => {
        ProviderApi.getOnGardReviewData(combinedData, userId)
            .then(res => {
                const mappedData = mapOnGardReviewDataFromServerToApp(res.body);
                setOnGardReviewData(mappedData);
                setLoading(false);
            })
            .catch(error => {
                handleErrorResponse("loading onGard review data", error, isAdmin);
                setLoading(false);
            });
    }
    const handleLoadReviewData = () => {
        if (isDemo) {
            handleLoadDemoReviewData();
        } else if (combinedData.gameType === GAME_TYPES.ON_GARD) {
            handleLoadOnGardReviewData();
        } else {
            handleLoadClinicReviewData();
        }
    };

    useEffect(() => {
        if (combinedData?.clinics?.length && combinedData.startDate) {
            setLoading(true);
            handleLoadReviewData();
        }
    }, [combinedData?.startDate, formData?.durationDays, formData?.comparisonPeriod, formData?.productType, formData.includeHomeDeliveryDoses, formData.excludeFreeDoses]);

    //Make sure that the clinicIds have changes before reloading the review data
    //  (otherwise there is an update every time the email/alias are changed)
    const previousClinicIds = usePrevious(flatMap(formData?.clinics, "clinicId"));

    useEffect(() => {
        const currentClinicIds = flatMap(formData?.clinics, "clinicId");
        if (combinedData?.clinics?.length && combinedData.startDate) {
            if (difference(currentClinicIds, previousClinicIds)?.length) {
                setLoading(true);
                handleLoadReviewData();
            }
        }
    }, [formData.clinics]);

    useEffect(() => {
        // Load template and all the things
        setLoading(true);
        if (props.id && (userId || isDemo)) {
            if (props.isGame) {
                getGame();
                if (!isDemo) {
                    getCanTransferGame();
                }
            } else if (props.isCopy) {
                if (isDemo) {
                    getGame();
                } else {
                    getGameCopy();
                }
            } else {
                getTemplate();
            }
        }
    }, [props.isGame, props.isCopy, props.id, userId]);

    if (!(isDemo || userId)) {
        return <SpinnerTakeover show />
    }

    const createDraft = (data) => {
        setLoading(true);
        const newData = cloneDeep(data);
        // Cleanup the data a bit, especially if it has a greenlineTemplateId
        if (!newData.ownerUserId) {
            newData.ownerUserId = userId;
        }
        const successMessageText = props.isUserTemplate ? "Template" : "Game";
        ProviderApi.saveGame(newData, userId)
            .then(res => {
                toast.success(`${successMessageText} Draft Created`);
                setLoading(false);
                onClose();
            })
            .catch(error => {
                handleErrorResponse(newData.isTemplate ? `updating template` : `creating game draft`, error, isAdmin);
                setLoading(false);
            });
    }
    const createGame = (data) => {
        setLoading(true);
        ProviderApi.saveGame(data, userId)
            .then(res => {
                toast.success(`${data.isTemplate ? "Template" : "Game"} Created Successfully`);
                setLoading(false);
                onClose();
            })
            .catch(error => {
                handleErrorResponse(`creating ${data.isTemplate ? "Template" : "Game"}`, error, isAdmin);
                setLoading(false);
            });
    }
    const updateGame = (data, closeAfter = false) => {
        setLoading(true);
        ProviderApi.updateGame(data, userId)
            .then(res => {
                toast.success(`${data.isTemplate ? "Template" : "Game"} Updated Successfully`);
                setLoading(false);
                if (closeAfter) {
                    onClose();
                }
            })
            .catch(error => {
                handleErrorResponse(`updating ${gameOrTemplate}`, error, isAdmin);
                logger.error(error.message);
            });
    }
    const getTaskErrors = (data) => {
        return filter(data.addOnConfig, task => {
            //If there are no fields that need configuration then return false
            if (!task.configuration) {
                return false;
            }
            const taskInput = find(data.config.Tasks, {OnGardTaskId: task.onGardTaskId});
            //Get the number of fields that have been filled out
            let numValues = 0
            if (!!taskInput?.RepInputAmount) {
                numValues += 1;
            }
            if (!!taskInput?.RepInputAmount1) {
                numValues += 1;
            }
            if (!!taskInput?.RepInputAmount2) {
                numValues += 1;
            }
            if (!!taskInput?.MaxPerGame) {
                numValues += 1;
            }
            if (!!task.configuration) {
                //compare the number of fields that need to be filled out to the number of fields that have been filled out
                return filter(task.configuration.repInputs, "required").length > numValues
            }
        });
    }
    const isDraftValidToSave = data => {
        // Game Name Validation
        if (!data?.name) {
            toast.error(`${gameOrTemplate} must have a Name`);
            return false;
        } else if (data?.name?.length > 50) {
            toast.error(`${gameOrTemplate} Name must be 50 characters or less`);
            return false;
        }
        // Date Validation
        if (!data.startDate) {
            toast.error(`${gameOrTemplate} must have a Start Date`);
            return false;
        } else {
            const startDate = moment(data.startDate);
            const tomorrow = moment(TOMORROW);
            if ((startDate < tomorrow) && !((props.canEditGamesAdmin || isAdmin) && isSaved)) {
                toast.error("Start Date must be in the Future")
                return false;
            } else if ((props.canEditGamesAdmin || isAdmin) && data.hasStarted && (startDate > tomorrow)) {
                toast.error(`Once a ${gameOrTemplate} has Started, Start Date can only be changed to before the Original Start Date`);
                return false;
            }
            const endDate = new moment(data.startDate).add((data.durationDays - 1), "days");
            if (endDate <= TODAY) {
                const maxStartDate = TODAY.subtract((data.durationDays - 2), "days").format("MM/DD/YYYY");
                toast.error(`Once a ${gameOrTemplate} has Started, it's End Date must be a date in the Future. Based on the selected Duration, the Start Date must be on or before ${maxStartDate}`);
                return false;
            }
        }
        return true;
    }
    const isGameValidToStart = data => {
        if (!isDraftValidToSave(data)) {
            return false;
        }
        // Clinic Validation
        switch (data.gameType) {
            case GAME_TYPES.GROWTH:
            case GAME_TYPES.ON_GARD:
                if (!data.clinics.length) {
                    toast.error(`${getNameForGameType(data.gameType)} game must have a clinic to start`);
                    return false;
                }
                break;
            case GAME_TYPES.DEATH_MATCH:
            case GAME_TYPES.PUPPY_BOWL:
            case GAME_TYPES.PUPPY_POOL_PARTY:
            case GAME_TYPES.LEAF_PARTY:
                if (!(data.clinics?.length > 1)) {
                    toast.error(`${getNameForGameType(data.gameType)} game must have at least 2 clinics to start`);
                    return false;
                }
                break;
            default:
                break;
        }
        // Product Validation
        if ((data.gameType === GAME_TYPES.GROWTH) || (data.gameType === GAME_TYPES.DEATH_MATCH)) {
            if (!data.products?.length) {
                toast.error(`${getNameForGameType(data.gameType)} game must have at least 1 product to start`);
                return false;
            }
        }
        const missingEmailErrors = filter(data?.clinics, clinic => {
            if (!(clinic?.emailList?.length)) {
                return true;
            }
            return !!filter(clinic.emailList, email => {
                return trim(email) === "";
            })?.length
        });
        if (!!missingEmailErrors?.length) {
            toast.error("An email must be provided for the clinic");
            return false;
        }
        const invalidEmailErrors = filter(data?.clinics, clinic => {
            return !!filter(clinic.emailList, email => {
                let regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return !email.match(regexEmail);
            })?.length
        });
        if (!!invalidEmailErrors?.length) {
            toast.error("Email is not valid");
            return false;
        }
        // Product Validation
        if (!data.products && data.products.length < 1 && data.gameType !== GAME_TYPES.ON_GARD) {
            toast.error(`${getNameForGameType(data.gameType)} ${gameOrTemplate} must have at least 1 product to start`);
            return false;
        }
        //GROWTH GAME ONLY - Check that level data is valid
        if (data.gameType === GAME_TYPES.GROWTH) {
            if (!data?.config?.Levels?.length) {
                toast.error("At least one level must be added");
                return false;
            }
            const levelErrors = [];
            const increaseErrors = filter(data?.config?.Levels, (level, index) => {
                if (!(level?.IncreasePercentage >= 0)) {
                    return true;
                }
                //Check that each level has an increase percentage that is greater than the last
                const previousLevel = data?.config?.Levels[index - 1];
                if (data?.config?.Levels[index - 1]) {
                    if (level?.IncreasePercentage <= previousLevel?.IncreasePercentage) {
                        levelErrors.push(index);
                    }
                }
            });
            if (!!levelErrors.length) {
                toast.error(`Level ${levelErrors[0] + 1} must have an increase percentage that is greater than the previous level`);
                return false;
            }
            if (increaseErrors.length) {
                toast.error("Levels cannot have a negative value for Percent Increase");
                return false;
            }
            const prizeErrors = filter(data?.config?.Levels, level => {
                if (!(level?.PrizeAmount > 0)) {
                    return true;
                }
            });
            if (prizeErrors.length) {
                toast.error("Total Cost for each level must be greater than 0");
                return false;
            }
            const roiErrors = filter(data?.config?.Levels, level => {
                if (!(level?.EstROI >= 1)) {
                    return true;
                }
            });
            if (roiErrors.length) {
                toast.error("All levels must have a Target ROI of at least 1");
                return false;
            }
        }
        //ON_GARD GAME ONLY - Check that level data is valid
        if (data.gameType === GAME_TYPES.ON_GARD) {
            const taskErrors = getTaskErrors(data);
            if (taskErrors.length) {
                toast.error("All tasks must have a value");
                return false;
            }
        }
        return true;
    };
    const handleChange = (data) => {
        const newData = {
            ...formData,
            ...data,
        }
        setFormData(newData);
    }
    const onClose = () => {
        history.push(ProviderLinks.getLinkLocation("games", {
            providerId,
            nodeId,
            isAdmin,
            isDemo,
        }));
    }
    const handleCreateTemplate = () => {
        if (props.canEditProviderBiGames) {
            const gameCopy = {
                ...combinedData,
                startDate: TOMORROW,
                isTemplate: true,
                copiedFromGreenlineGameId: combinedData.greenlineGameId,
                greenlineGameId: 0,
                originalStartDate: TODAY.format("MM/DD/YYYY")
            };
            if (isDraftValidToSave(gameCopy)) {
                setLoading(true);
                createGame(gameCopy);
            }
        }
    }
    const handleSaveDraftClick = () => {
        if (isDraftValidToSave(combinedData)) {
            const newData = {
                ...cloneDeep(combinedData),
                config: {
                    ...cloneDeep(combinedData.config),
                    Levels: map(combinedData.config.Levels, l => {
                        return {
                            ...l,
                            IncreasePercentage: !!Number(l?.IncreasePercentage) ? Number(l?.IncreasePercentage) : 0,
                            PrizeAmount: !!Number(l?.PrizeAmount) ? Number(l?.PrizeAmount) : 0,
                            EstROI: !!Number(l?.PrizeAmount) ? Number(l?.EstROI) : 0
                        }
                    })
                },
                clinics: map(combinedData.clinics, c => {
                    const clinicAlias = props.clinics[c.clinicId]?.clinicAlias;
                    return {
                        ...c,
                        clinicAlias: !!c?.clinicAlias ? c.clinicAlias : clinicAlias
                    }
                })
            };
            if (props.isGame) {
                updateGame(newData, true);
            } else {
                //For Templates and Game Copies
                createDraft(newData);
            }
        }
    };
    const handleCloseStartNotification = () => {
        setShowNotification(false);
        setSaveData(null);
    }
    const handleStart = (startData) => {
        if(isGameValidToStart(startData)) {
            handleCloseStartNotification(true);
            if (props.isGame) {
                // Update Game to start
                updateGame(startData, true);
            } else {
                // Create a game from a Template or a Game Copy
                createGame(startData);
            }
        }
    }
    const handleSaveAndStartClick = () => {
        if (isGameValidToStart(combinedData)) {
            const newData = {
                ...cloneDeep(combinedData),
                newGameState: SAVED,
                clinics: map(combinedData.clinics, c => {
                    const clinicAlias = props.clinics[c.clinicId]?.clinicAlias;
                    return {
                        ...c,
                        clinicAlias: !!c?.clinicAlias ? c.clinicAlias : clinicAlias
                    }
                })
            };
            if (props.gameStartNotification) {
                setShowNotification(true);
                setSaveData(newData);
            } else {
                handleStart(newData);
            }
        }
    };

    const onClearGame = () => {
        setFormData({});
        // To completely reset games that are already saved, just change to the empty template pages.
        if (!isNaN(props.id)) {
            if (combinedData.gameType === GAME_TYPES.GROWTH) {
                history.replace(ProviderLinks.getLinkLocation("game-template", {providerId, nodeId, isAdmin, isDemo, templateId:"growth-doses"}))
            } else if (combinedData.gameType === GAME_TYPES.DEATH_MATCH) {
                history.replace(ProviderLinks.getLinkLocation("game-template", {providerId, nodeId, isAdmin, isDemo, templateId:"challenge-match"}))
            }
        }
        toast.success("Game Cleared and Reset")
    };

    const handleDeleteClick = () => {
        if (props.canEditProviderBiGames) {
            if (confirm(`Are you sure you want to DELETE ${gameData.name}?`)) {
                ProviderApi.deleteGame(gameData.greenlineGameId, userId)
                    .then(res => {
                        toast.success(`Game Deleted Successfully`);
                        onClose();
                    })
                    .catch(error => {
                        handleErrorResponse(`deleting ${gameOrTemplate}`, error, isAdmin);
                    });
            }
        }
    }

    const formIsValid = () => {
        if (!combinedData.config) {
            return false;
        }
        const invalid = filter(combinedData.config?.Levels, level => (
            !level.IncreasePercentage || !level.PrizeAmount || !level.EstROI
        ));
        //Make sure that All required fields for onGard Games are filled out
        const invalidAddOnConfig = getTaskErrors(combinedData);
        if (combinedData.gameType === GAME_TYPES.GROWTH || combinedData.gameType === GAME_TYPES.GROWTH_NGP) {
            if (invalid.length) {
                if (!combinedData.products?.length) {
                    return false;
                }
            }
            if (!intersection(uniq(flatMap(reviewData?.data, "productBrandId") || []), flatMap(combinedData.products, "productBrandId"))?.length) {
                return false;
            }
        } else {
            // All games accept Growth Doses and NG Plus Growth Doses should have multiple clinics selected
            if (combinedData.clinics?.length < 2) {
                return false;
            }

            // Challenge match games are required to have PrizeAmounts, each with a name and prize amount
            if (getIsChallengeMatchGame(combinedData.gameType)) {
                // Validate PrizeAmounts
                const configPrizeAmounts = get(combinedData, "config.PrizeAmounts", []);
                if (configPrizeAmounts.length === 0) {
                    // Must have at least 1 prize
                    return false;
                }

                // Every prize should have a valid DisplayName and PrizeAmount
                let areConfigPrizeAmountsValid = true;
                configPrizeAmounts.map(configPrizeData => {
                    const prizeDisplayName = configPrizeData.DisplayName;
                    const prizeAmount = configPrizeData.PrizeAmount;

                    const isPrizeDisplayNameValid = !!prizeDisplayName && trim(prizeDisplayName).length > 0;
                    const isPrizeAmountValid = !!prizeAmount && trim(prizeAmount).length > 0;
                    if (!isPrizeDisplayNameValid || !isPrizeAmountValid) {
                        areConfigPrizeAmountsValid = false;
                    }
                });
                if (!areConfigPrizeAmountsValid) {
                    return false;
                }
            }
        }

        if (combinedData.gameType === GAME_TYPES.ON_GARD) {
            if (invalidAddOnConfig.length) {
                return false;
            }
        }

        return !!combinedData.clinics?.length;
    }

    const startEnabled = !!(canEdit && formIsValid());

    const handleReassign = () => {
        if (reassign?.assignToUserId && props.canAdminProviderBiGames) {
            if (confirm(`${reassign.title}?`)) {
                const data = {
                    gameId: props.id,
                    modUserId: props.userProfile?.id,
                    newOwnerId: reassign.assignToUserId
                }
                props.reassignGame(data);
                onClose();
            }
        }
    };

    const renderOnGardReview = () => {
        if (!onGardReviewData) {
            return null;
        }
        return (
            <div className="flex padding-md" style={{borderTop: "1px solid #ddd"}}>
                <div className="flex-1">
                    <h3 className="text-center margin-bottom-x-sm">
                        Previous Period
                        ({moment(onGardReviewData.reportPeriodStartDate).format("MM/DD/YYYY")} - {moment(onGardReviewData.reportPeriodEndDate).format(("MM/DD/YYYY"))})
                    </h3>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Category</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {map(onGardReviewData.data, row => {
                            return (
                                <tr key={`row_${row.id}`}>
                                    <td>
                                        {row.description}
                                    </td>
                                    <td>
                                        {!!row.totalCount ? row.totalCount : "No"} {row.units}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    const renderTaskSection = () => {
        return (
            <div>
                <div style={{borderTop: "1px solid #ddd"}}>
                    <GameTaskEditor
                        data={combinedData}
                        onChange={handleChange}
                        gameType={combinedData.gameType}
                    />
                </div>
                {renderOnGardReview()}
            </div>
        )
    };

    return (
        <div className={styles.root}>
            <div className="full-width flex justify-center column-to-row-sm">
                <div className="flex-1-sm margin-left-sm">
                    <h2>{gameData.name}</h2>
                </div>
                {(reassign?.canReassign && props.canAdminProviderBiGames) && (
                    <div className="flex-none margin-bottom-x-sm">
                        <Button type="primary"
                            onClick={handleReassign} // Allows clinics to assign the game to another owner
                        >
                            Reassign
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.mainSection} style={{ borderBottom: "1px solid #ddd" }}>
                <div className={styles.gameContainer} >
                    <div className={styles.gameDetails}>
                        <div className="margin-bottom-sm">
                            <GameDetailsForm
                                location={props.location}
                                isSaved={isSaved}
                                data={combinedData}
                                onChange={handleChange}
                                readOnly={!canEdit}
                                isAdmin={isAdmin}
                            />
                        </div>
                        {isGrowthGame && (
                            <div className="padding-bottom-md">
                                <GameParasiticideProductsTable
                                    data={combinedData}
                                    readOnly={!canEdit}
                                    reviewData={reviewData}
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex-1-sm padding-top-md">
                        <GameCalculations
                            userId={userId}
                            data={combinedData}
                            reviewData={reviewData}
                            readOnly={!canEdit}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                {renderTaskSection()}
            </div>
            <div className="full-width padding-md flex-none" style={{ backgroundColor: "#f5f5f5", borderBottom: "1px solid #ddd" }}>
                <div className="flex justify-evenly column-to-row-sm">
                    {(!isDemo && gameData.canUseAsTemplate) && (
                        <Button
                            disabled={!props.canEditProviderBiGames}
                            onClick={handleCreateTemplate}
                            type="warn"
                        >
                           Create Template {!!combinedData.greenlineGameId ? "from Game" : ""}
                        </Button>
                    )}
                    {!isDemo && !isSaved && canEdit && (
                        <Button
                            onClick={handleSaveDraftClick}
                            disabled={!canEdit}
                        >
                            {combinedData.isTemplate && props.isUserTemplate ? "Update Template" : "Save Draft"}
                        </Button>
                    )}
                    {combinedData.isTemplate && props.isUserTemplate ? (
                        //FOR TEMPLATE
                        <>
                            {!isDemo && props.canEditProviderBiGames && (
                                <Button
                                    onClick={handleDeleteClick}
                                    disabled={!props.canEditProviderBiGames}
                                    type="danger"
                                >
                                    Delete Template
                                </Button>
                            )}
                        </>
                    ) : (
                        //FOR GAME
                        <>
                            {!isDemo && canEdit && (
                                <Button
                                    onClick={handleSaveAndStartClick}
                                    disabled={!startEnabled}
                                >
                                    {isSaved ? "Resubmit" : "Submit"} and Start Game
                                </Button>
                            )}
                            {canDelete && props.isGame && (
                                <Button
                                    onClick={handleDeleteClick}
                                    disabled={!canDelete}
                                    type="danger"
                                >
                                    Delete Game
                                </Button>
                            )}
                        </>
                    )}
                    {isDemo && (
                        <Button onClick={onClearGame}>
                           Clear Game and reset
                        </Button>
                    )}
                    {canEdit ? (
                       <Button
                            onClick={onClose}
                            type="gray"
                        >
                           Cancel
                        </Button>
                    ) : (
                        <Button
                            onClick={onClose}
                            type="success"
                        >
                           Done
                        </Button>
                    )}
                </div>
            </div>
            <div className="full-width margin-top-md">
                <BiProductDisclosures />
            </div>
            <Modal
                show={showNotification}
                onClose={() => setShowNotification(false)}
                medium
            >
                {props.gameStartNotification && (
                    <GameStartNotification
                        additionalData={saveData}
                        partnerProgramIdentifier={props.gameStartNotification.partnerProgramIdentifier}
                        onClose={handleCloseStartNotification}
                        onSubmit={handleStart}
                    />
                )}
            </Modal>
            <SpinnerTakeover show={loading} />
        </div>
    );
}
EditGame.propTypes = {
    id: PropTypes.any.isRequired,
    isGame: PropTypes.bool,
    isCopy: PropTypes.bool,
    isUserTemplate: PropTypes.bool,
}

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canEditProviderBiGames = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canEditGamesAdmin = userHasPermission(PermissionTypes.EDIT, UserPermissions.GAMES_ADMIN, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        return {
            userProfile,
            clinics: state.entities.providerGamesClinics,
            gameStartNotification: find(state.entities.allNotifications, {notificationType: GAMES_DISCLAIMER, isVisibleInNotifications: false}),
            // Permissions
            canEditProviderBiGames,
            canAdminProviderBiGames,
            canEditGamesAdmin,
            canViewClinicBIGamesDashboard,
        };
    },
    (dispatch) => ({
        getBiGamesClinics: (userId) => dispatch(ProviderActions.getBiGamesClinics(userId)),
        getBiGamesDemoClinics: () => dispatch(ProviderActions.getBiGamesDemoClinics()),
        getAllNotifications: () => dispatch(NotificationActions.getAllNotifications()),
        reassignGame: (data) => dispatch(ProviderActions.reassignGame(data)),
    })
);
export default compose(
    withRouter,
    connector
) (EditGame);
