import React, { useState } from "react";
import PropTypes from "prop-types";
import GenericAnimation from "./GenericAnimation";

export default function MultipleAnimations(props) {
	const {
		animations,
		onAnimationCompleted,
		onAnimationStarted,
		onBeforeAnimation,
		repeat = false,
	} = props;
	const [currentAnimation, setCurrentAnimation] = useState(0);

	const handleAnimationComplete = () => {
		if (!!onAnimationCompleted) {
			onAnimationCompleted(currentAnimation);
		}
		if (currentAnimation + 1 < animations.length) {
			if (!!onBeforeAnimation) {
				onBeforeAnimation(currentAnimation + 1);
			}
			setCurrentAnimation(currentAnimation + 1);
		} else if (repeat) {
			if (!!onBeforeAnimation) {
				onBeforeAnimation(0);
			}
			setCurrentAnimation(0);
		}
	};

	if (!animations?.[currentAnimation]) {
		return null;
	}

	return (
        <GenericAnimation
	        key={currentAnimation}
            {...animations[currentAnimation]}
            onAnimationComplete={handleAnimationComplete}
            onAnimationStarted={onAnimationStarted}
        />
    );
}

MultipleAnimations.propTypes = {
	animations: PropTypes.arrayOf(PropTypes.shape({
		altText: PropTypes.string.isRequired,
		animationDuration: PropTypes.number, // How long should the whole animation play (Repeat animation for X seconds)
		duration: PropTypes.number.isRequired, // How long does it take for the animation to play once
		frames: PropTypes.arrayOf(PropTypes.shape({
			image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // If image is different then the default
			duration: PropTypes.number, // If duration should not be an equal part of the animation
			top: PropTypes.string,
			right: PropTypes.string,
			bottom: PropTypes.string,
			left: PropTypes.string,
		})).isRequired,
		height: PropTypes.string.isRequired,
		image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
		isAnimated: PropTypes.bool,
		isTransparent: PropTypes.bool,
		lastFrame: PropTypes.number,
		left: PropTypes.string,
		top: PropTypes.string,
		width: PropTypes.string.isRequired,
	})).isRequired,
	onAnimationCompleted: PropTypes.func,
	onAnimationStarted: PropTypes.func,
	onBeforeAnimation: PropTypes.func,
	repeat: PropTypes.bool,
};
