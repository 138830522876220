import getNameForGameType from "utils/getNameForGameType";
import map from "lodash/map";
import values from "lodash/values";

/// Use utils/getNameForGameType.js to get the display name.

export const GRAND_SLAM = "GrandSlam";
export const GOLDEN_SUMMER = "GoldenSummer";
export const GROWTH = "GrowthDoses";
export const GROWTH_NGP = "GrowthDosesNGP";
export const DEATH_MATCH = "DeathMatch";
export const DEATH_MATCH_NGP = "DeathMatchNGP";
export const LEAF_PARTY = "LeafParty";
export const ON_GARD = "OnGard";
export const PUPPY_BOWL = "PuppyBowl";
export const PUPPY_BOWL_2 = "PuppyBowl2";
export const PUPPY_POOL_PARTY = "PoolParty";

export const GAME_TYPES = {
    GRAND_SLAM: GRAND_SLAM,
    GOLDEN_SUMMER: GOLDEN_SUMMER,
    GROWTH: GROWTH,
    GROWTH_NGP: GROWTH_NGP,
    DEATH_MATCH: DEATH_MATCH,
    DEATH_MATCH_NGP: DEATH_MATCH_NGP,
    LEAF_PARTY: LEAF_PARTY,
    ON_GARD: ON_GARD,
    PUPPY_BOWL: PUPPY_BOWL,
    PUPPY_BOWL_2: PUPPY_BOWL_2,
    PUPPY_POOL_PARTY: PUPPY_POOL_PARTY,
};

export const GAME_TYPE_OPTIONS = map(GAME_TYPES, t => ({
    name: getNameForGameType(t),
    value: t
}));

export const MUTLI_CLINIC_GAMES = {
    [GRAND_SLAM]: true,
    [GOLDEN_SUMMER]: true,
    [GROWTH]: false,
    [GROWTH_NGP]: false,
    [DEATH_MATCH]: true,
    [DEATH_MATCH_NGP]: true,
    [ON_GARD]: true,
    [PUPPY_BOWL]: true,
    [PUPPY_BOWL_2]: true,
    [PUPPY_POOL_PARTY]: true,
    [LEAF_PARTY]: true,
};

export const GAME_TYPE_CONFIGS = {
    [GRAND_SLAM]: {
        multiClinic: MUTLI_CLINIC_GAMES[GOLDEN_SUMMER],
        isGrowth: false,
        isChallenge: true,
    },
    [GOLDEN_SUMMER]: {
        multiClinic: MUTLI_CLINIC_GAMES[GOLDEN_SUMMER],
        isGrowth: false,
        isChallenge: true,
    },
    [GROWTH]: {
        multiClinic: false,
        isGrowth: true,
        isChallenge: false,
    },
    [GROWTH_NGP]: {
        multiClinic: false,
        isGrowth: true,
        isChallenge: false,
    },
    [DEATH_MATCH]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [DEATH_MATCH_NGP]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [ON_GARD]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [PUPPY_BOWL]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [PUPPY_BOWL_2]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [PUPPY_POOL_PARTY]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [LEAF_PARTY]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [LEAF_PARTY]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
}
