import React from "react";
import PropTypes from "prop-types";
import GamesDeathMatchCard from "components/games/elements/GamesDeathMatchCard";
import GamesGrowthDosesCard from "components/games/elements/GamesGrowthDosesCard";
import GamesOnGardCard from "components/games/elements/GamesOnGardCard";
import GamesPuppyBowlCard from "components/games/elements/GamesPuppyBowlCard";
import GenericGameCard from "components/games/elements/GenericGameCard";
import GoldenSummerGamesCard from "components/games/elements/GoldenSummerGamesCard/GoldenSummerGamesCard";
import PuppyPoolPartyCard from "components/games/elements/PuppyPoolPartyCard";
import PuppyLeafPartyCard from "components/games/elements/PuppyLeafPartyCard";
import { GAME_TYPES } from "constants/GameTypes";
import PuppyBowl2Card from "components/games/elements/PuppyBowl2Card";

export default function GreenlineGameCard({ game, dashboard, forceHistoric, clinicId, onCardClick, nodeId}) {
    switch (game.gameType) {
        case GAME_TYPES.DEATH_MATCH:
        case GAME_TYPES.DEATH_MATCH_NGP:
            return (
                <GamesDeathMatchCard
                    clinicId={clinicId}
                    game={game}
                    key={game.greenlineGameId}
                    dashboard={dashboard}
                    onCardClick={onCardClick}
                    nodeId={nodeId}
                />
            );
        case GAME_TYPES.GROWTH:
        case GAME_TYPES.GROWTH_NGP:
            return (
                <GamesGrowthDosesCard
                    game={game}
                    key={game.greenlineGameId}
                    dashboard={dashboard}
                    onCardClick={onCardClick}
                />
            );
        case GAME_TYPES.ON_GARD:
            return (
                <GamesOnGardCard
                    clinicId={clinicId}
                    game={game}
                    key={game.greenlineGameId}
                    dashboard={dashboard}
                    onCardClick={onCardClick}
                />
            );
        case GAME_TYPES.PUPPY_BOWL:
            return (
                <GamesPuppyBowlCard
                    clinicId={clinicId}
                    nodeId={nodeId}
                    game={game}
                    key={game.greenlineGameId}
                    dashboard={dashboard}
                    snapshot={!!nodeId}
                />
            );
        case GAME_TYPES.PUPPY_POOL_PARTY:
            return (
                <PuppyPoolPartyCard
                    clinicId={clinicId}
                    nodeId={nodeId}
                    game={game}
                    key={`${game.greenlineGameId}_1`}
                    snapshot={!!nodeId}
                />
            );
        case GAME_TYPES.LEAF_PARTY:
            return (
                <PuppyLeafPartyCard
                    clinicId={clinicId}
                    game={game}
                    key={`${game.greenlineGameId}_1`}
                    snapshot={!!nodeId}
                />
            );
        case GAME_TYPES.PUPPY_BOWL_2:
            return (
                <PuppyBowl2Card
                    clinicId={clinicId}
                    dashboard={dashboard}
                    game={game}
                    key={`${game.greenlineGameId}_1`}
                    nodeId={nodeId}
                    snapshot={!!nodeId}
                />
            );
        case GAME_TYPES.GOLDEN_SUMMER:
            return (
                <GoldenSummerGamesCard
                    clinicId={clinicId}
                    game={game}
                    key={`${game.greenlineGameId}_1`}
                    nodeId={nodeId}
                    snapshot={!!nodeId}
                />
            );
        default:
            return (
                <GenericGameCard
                    clinicId={clinicId}
                    nodeId={nodeId}
                    game={game}
                    key={`${game.greenlineGameId}_1`}
                    snapshot={!!nodeId}
                />
            );
    }
}

GreenlineGameCard.propTypes = {
    game: PropTypes.object,
    userId: PropTypes.number,
    dashboard: PropTypes.bool,
    clinicId: PropTypes.number,
    nodeId: PropTypes.number,
};
