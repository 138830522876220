import React, { Fragment, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import * as styles from "./GameDetailsForm.scss";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import keys from "lodash/keys";
import map from "lodash/map";
import values from "lodash/values";
import Button from "components/common/Button";
import ChooseClinics from "components/provider/widgets/ChooseClinics";
import DateInput from "components/common/DateInput";
import Dropdown from "components/common/Dropdown";
import DropdownSearch from "components/common/DropdownSearch";
import PointsLegend from "components/games/widgets/PointsLegend";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import getNameForGameComparisonPeriod from "utils/getNameForGameComparisonPeriod";
import getNameForGameDuration from "utils/getNameForGameDuration";
import { getCategoryNameForGameType } from "utils/getGameTypeDetails";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { GAME_COMPARISON_PERIODS } from "constants/GameComparisonPeriods";
import { GAME_DURATIONS } from "constants/GameDurations";
import { GAME_PRODUCT_TYPES } from "constants/GameProductTypes";
import {GAME_TYPE_OPTIONS, GOLDEN_SUMMER, GROWTH, MUTLI_CLINIC_GAMES, ON_GARD} from "constants/GameTypes";
import * as UserPermissions from "constants/UserPermissions";

const PRODUCT_TYPE_OPTIONS = map(values(GAME_PRODUCT_TYPES), t => ({ name: getCategoryNameForGameType(t), value: t }));
const DURATION_OPTIONS = map(values(GAME_DURATIONS), d => ({ name: getNameForGameDuration(d), value: Number(d) }));
const COMPARISON_PERIOD_OPTIONS = map(values(GAME_COMPARISON_PERIODS), p => ({ name: getNameForGameComparisonPeriod(p), value: p }));
const EMPTY_CLINICS = [
    {
        clinicId: null,
        clinicName: "",
        clinicAlias: "",
        emailList: [""],
    },
];
function GameDetailsForm(props) {
    const { data, clinics } = props;
    const gameOrTemplate = !!data.canUseAsTemplate && !data.greenlineGameTemplateId ? "Template" : "Game";
    const config = data?.config || {};
    const isMultiClinicGame = useMemo(() => MUTLI_CLINIC_GAMES[props.data.gameType] || false, [props.data]);
    // Make sure the clinic names shows for games that have been started (even if clinic is no longer current)
    const options_clinics = !!keys(clinics)?.length ? clinics : data.clinics;
    const CLINIC_OPTIONS = map(options_clinics, data => ({ name: data.clinicName, value: data.clinicId }));
    const TODAY = new moment();
    //Admins can pull the startDate back
    const MAX_DATE = data.gameType === 'PoolParty' ? (
        moment(config.StartWindowEndDate).add(1, 'day').format('MM/DD/YYYY')
    ) : config.GameStartsOrOnBefore ? (
        moment(config.GameStartsOrOnBefore).add(1, 'day').format('MM/DD/YYYY')
    ) : (
        ((props.canAdminProviderBiGames || props.isAdmin) && props.isSaved && data.hasStarted) ? data.originalStartDate : null
    );
    const MIN_DATE = data.gameType === 'PoolParty' ? (
        moment(config.StartWindowBeginDate).add(1, 'day').format('MM/DD/YYYY')
    ) : config.GameStartsOrOnAfter ? (
        moment(config.GameStartsOrOnAfter).add(1, 'day').format('MM/DD/YYYY')
    ) : (
        ((props.canAdminProviderBiGames || props.isAdmin) && props.isSaved) ? TODAY.subtract((data.durationDays - 2), "days").format("MM/DD/YYYY") : TODAY.add(1, "day").format("MM/DD/YYYY")
    );

    const handleChange = ({ name, value }, index=null) => {
        let products = data?.products || [];
        switch(name) {
            case "clinicId":
                //CLINIC CHANGES
                let newClinics = data.clinics ? cloneDeep(data.clinics) : [];
                let newClinic;
                if (!value) {
                    newClinics.splice(index, 1);
                } else {
                    const clinic = find(clinics, {clinicId: value}) || {}
                    const clinicEmail = clinic.clinicEmail || ""
                    newClinic = {
                        ...newClinics[index],
                        [name]: value,
                        emailList: [clinicEmail]
                    }
                    newClinics.splice(index, 1, newClinic);
                }
                if (data.gameType === GROWTH) {
                    // Only change the productTypes if they are not locked
                    products = config.ProductTypeLocked ? products : [];
                }
                props.onChange({
                    clinics: newClinics,
                    products: products
                });
                break;
            case "emailList":
                //CLINIC CHANGES
                const newEClinics = data.clinics ? cloneDeep(data.clinics) : [];
                const newEClinic = {
                    ...newEClinics[index],
                    [name]: value
                }
                newEClinics.splice(index, 1, newEClinic);

                props.onChange({
                    clinics: newEClinics,
                });
                break;
            case "productType":
            case "comparisonPeriod":
                props.onChange({
                    [name]: value,
                    products: config.ProductTypeLocked ? products : [],
                });
                break;
            default:
                props.onChange({[name]: value});
        }
    };
    const addEmail = (clinicIndex, clinicId) => {
        const clinic = find(data.clinics, {clinicId: clinicId});
        const newEmailList = [...clinic.emailList]
        newEmailList.push("");
        handleChange({name: "emailList", value: newEmailList}, clinicIndex)
    };
    const removeEmail = (clinicIndex, emailIndex) => {
        const newEmailList = [...data.clinics[clinicIndex].emailList]
        newEmailList.splice(emailIndex, 1);
        handleChange({name: "emailList", value: newEmailList}, clinicIndex);
    };
    const handleEmailListChange = ({name, value}, clinicIndex, emailIndex) => {
        const newEmailList = [...data.clinics[clinicIndex].emailList];
        newEmailList.splice(emailIndex, 1, value);
        handleChange({name: name, value: newEmailList}, clinicIndex);
    };
    if(!data){
        return <SpinnerTakeover show/>
    }
    return (
        <form className={classnames(styles.root, "full-width")}>
            <div className="full-width">
                <TextBox
                    name="name"
                    value={data.name}
                    label={`${gameOrTemplate} Name`}
                    required
                    minLength={4}
                    maxLength={50}
                    pattern="[A-Za-z]+[A-Za-z0-9_.]"
                    onChange={handleChange}
                    disabled={props.readOnly || (config && config.GameNameLocked)}
                />
            </div>
            {data.gameType !== ON_GARD && (
                <>
                    <div className="full-width flex column-to-row-sm">
                        <div className="flex-1 margin-right-sm form-group">
                            <Dropdown
                                name="gameType"
                                value={data.gameType}
                                label={`${gameOrTemplate} Type`}
                                options={GAME_TYPE_OPTIONS}
                                onChange={handleChange}
                                // disabled={props.readOnly || (config && config.gameTypeLocked)}
                                disabled
                            />
                        </div>
                        <div className="flex-1 form-group">
                            <Dropdown
                                name="productType"
                                value={data.productType}
                                label="Product Type"
                                options={PRODUCT_TYPE_OPTIONS}
                                onChange={handleChange}
                                disabled={props.readOnly || (config && config.ProductTypeLocked)}
                            />
                        </div>
                    </div>
                </>
            )}
            <div className="full-width flex spaced-content column-to-row-sm">
                <div className="flex spaced-content align-center">
                    <DateInput
                        name="startDate"
                        value={data.startDate}
                        label="Start Date"
                        dateFormat={"MM/DD/YYYY"}
                        minDate={MIN_DATE}
                        maxDate={MAX_DATE}
                        showDropdowns
                        // icon={<i className="fa fa-calendar-alt text-primary" />}
                        onChange={handleChange}
                        disabled={props.readOnly || (config?.StartDateLocked)}
                        required
                        hasError={!props.readOnly && (new Date(data.startDate) < new Date(MIN_DATE))}
                    />
                </div>
                <div className="flex-1 form-group">
                    <Dropdown
                        className="flex-1"
                        name="durationDays"
                        value={data.durationDays}
                        label="Duration"
                        options={DURATION_OPTIONS}
                        onChange={data => handleChange({ name: data.name, value: Number(data.value) })}
                        disabled={props.readOnly || (config && config.DurationDaysLocked)}
                    />
                </div>
                {data.gameType !== ON_GARD && (
                    <div className="flex-1 form-group">
                        <Dropdown
                            className="flex-1"
                            name="comparisonPeriod"
                            value={data.comparisonPeriod}
                            label="Comparison Period"
                            options={COMPARISON_PERIOD_OPTIONS}
                            onChange={handleChange}
                            disabled={props.readOnly ||(config && config.ComparisonPeriodLocked)}
                        />
                    </div>
                )}
            </div>
            {props.data?.config?.showEvents && props.data?.config?.Events?.length && (
                <div>
                    <h4>Events:</h4>
                        <table className="table striped-green">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Length</th>
                            </tr>
                        </thead>
                        <tbody>
                            {map(props.data.config.Events, e => (
                                <tr>
                                    <td>{e.EventId}</td>
                                    <td>{e.EventName}</td>
                                    <td>{e.EventLength}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {props.data?.config?.Multipliers?.length && (
                <PointsLegend legend={props.data?.config?.Multipliers} />
            )}
            {isMultiClinicGame ? (
                <div className="margin-top-md margin-bottom-md">
                    {/* Passes back { name: "clinics", value: Array} */}
                    <ChooseClinics
                        location={props.location}
                        data={data}
                        onChange={handleChange}
                        readOnly={props.readOnly}
                    />
                </div>
            ) : (
                map(!!data.clinics?.length ? data.clinics : EMPTY_CLINICS, (clinic, index) => (
                    <Fragment key={clinic.clinicId}>
                        <DropdownSearch
                            name="clinicId"
                            value={clinic.clinicId}
                            label="Clinic"
                            placeholder="Search Clinics"
                            firstOption="Select a Clinic"
                            options={CLINIC_OPTIONS}
                            onChange={(data) => handleChange(data, index)}
                            disabled={props.readOnly}
                            // hasError={}
                            required
                        />
                        <div className="full-width">
                            <div className="margin-top-x-sm padding-sm">Email List</div>
                            {/*If there is no email in the clinic email list then use [""] as the list instead*/}
                            {map(clinic.emailList?.length ? clinic.emailList : [""], (email, i) => {
                                const isFirst = (i === 0);
                                const isLast = (i === (clinic.emailList.length - 1));
                                const selectedClinic = !!keys(clinics)?.length ? clinics[clinic?.clinicId] : {};
                                const emailHasValue = !!selectedClinic?.clinicEmail && selectedClinic?.clinicEmail !== "";
                                return(
                                    <div className="flex flex-end spaced-content" key={i}>
                                        <div className="flex-1">
                                            <div className={styles.email}>
                                                <TextBox
                                                    placeholder={isFirst ? (!clinic?.clinicId ? "No Clinic Selected" : "Enter an Email for the Clinic") : ""}
                                                    name="emailList"
                                                    value={email}
                                                    minLength={6}
                                                    onChange={(data) => handleEmailListChange(data, index, i)}
                                                    disabled={!clinic?.clinicId || props.readOnly}
                                                    inputType="email"
                                                />
                                                {(!isFirst) && (
                                                    <div className={styles.delete}>
                                                        <Button
                                                            iconOnly
                                                            text
                                                            onClick={() => removeEmail(index, i)}
                                                            disabled={props.readOnly}
                                                        >
                                                            <i className="far fa-times text-danger"/>
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex-none margin-bottom-x-sm">
                                            {isLast ? (
                                                <Button
                                                    iconOnly
                                                    onClick={() => addEmail(index, clinic.clinicId)}
                                                    disabled={props.readOnly || !isLast || !emailHasValue}
                                                >
                                                    <i className="fa fa-plus"/>
                                                </Button>
                                            ) : <div className="margin-sm"/>}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Fragment>
                ))
            )}
        </form>
    );
}

GameDetailsForm.propTypes = {
    onChange: PropTypes.func.isRequired, // Callback which receives current form data
    data: PropTypes.object, // Default values for the form data fields (if any)
    readOnly: PropTypes.bool,
    isSaved: PropTypes.bool,
    isAdmin: PropTypes.bool
}

GameDetailsForm.defaultProps = {
    isReview: false,
}
export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        return {
            canAdminProviderBiGames,
            clinics: state.entities.providerGamesClinics
        }
    }
)(GameDetailsForm);
