import { GAME_TYPES } from "constants/GameTypes";

export default function getNameForGameType(gameTypeId) {
    switch (gameTypeId) {
        case GAME_TYPES.GRAND_SLAM: return "Grand Slam Game";
        case GAME_TYPES.GOLDEN_SUMMER: return "Golden Summer Games";
        case GAME_TYPES.GROWTH: return "Growth (Doses)";
        case GAME_TYPES.GROWTH_NGP: return "Growth (Doses) with NG+";
        case GAME_TYPES.DEATH_MATCH: return "Challenge Match";
        case GAME_TYPES.DEATH_MATCH_NGP: return "Challenge Match with NG+"
        case GAME_TYPES.ON_GARD: return "On Gard";
        case GAME_TYPES.PUPPY_BOWL: return "Puppy Bowl 2023";
        case GAME_TYPES.PUPPY_BOWL_2: return "Pets Bowl 2024";
        case GAME_TYPES.PUPPY_POOL_PARTY: return "Puppy Pool Party 2023";
        case GAME_TYPES.LEAF_PARTY: return "Leaf Party 2023";
    }

    return gameTypeId;
}
