import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import produce from "immer";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import map from "lodash/map";
import snakeCase from "lodash/snakeCase";
import split from "lodash/split";
import words from "lodash/words";
import * as CouponsApi from "api/CouponsApi";
import * as NotificationsApi from "api/NotificationsApi";
import * as NotificationActions from "actions/NotificationsActions";
import AdobeOnly from "components/notifications/forms/AdobeOnly";
import BasicNotification from "components/notifications/forms/BasicNotification";
import BiGamesForm from "components/notifications/forms/BiGamesForm";
import BiIRForm from "components/notifications/forms/BiIRForm";
import ClinicInformationUpdate from "components/notifications/forms/ClinicInformationUpdate"
import GreenlineGivingForm from "components/notifications/forms/GreenlineGivingForm";
import GreenlineSupportPhoneNumber from "components/support/elements/GreenlineSupportPhoneNumber";
import LapsedUserOptIn from "components/notifications/forms/LapsedUserOptIn";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import VetCheckOfferForm from "components/notifications/forms/VetCheckOfferForm";
import ZoetisOfferForm from "components/notifications/forms/ZoetisOfferForm";
import { mapSingleNotificationFromServerToApp } from "data/serverMapping";
import logger from "utils/logger";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import {
    BI_ET_NON_CC_OPTIN,
    BI_GAMES,
    BI_IR_FORM,
    CANCELED,
    DECLINED,
    ENROLLED,
    GAMES_SURVEY,
    GREENLINE_GIVING,
    LAPSED_USER_OPT_IN,
    ORAVET_2022,
    PUPPY_GRADUATION_OPT_IN,
    VETCHECK,
    CLINIC_INFORMATION_UPDATE,
    MERCK_ADOBE_ONLY,
    ADOBE_ONLY,
    ZOETIS_PARASITICIDE_PILOT,

} from "constants/Notifications";
import * as ProviderIds from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";
import SurveyNotification from "../forms/SurveyNotification";

function Notification(props) {
    const {
        canViewClinicCouponLibrary = false,
        clinicId,
        isPreview = false,
        notification,
        onClose,
        providerId,
        setNotificationState,
        setProviderNotificationState,
    } = props;

    const [loading, setLoading]  = useState(false);
    const [details, setDetails]  = useState({});
    const [offerCloos, setOfferCloos]  = useState(null);
    const [offerGroups, setOfferGroups] = useState(null);
    const [selectedProviderId, setSelectedProviderId]  = useState(null);
    const [offers, setOffers] = useState([]);
    const [acceptedOffers, setAcceptedOffers] = useState([]);
    const [selectedOfferGroups, setSelectedOfferGroups] = useState([]);
    const notificationId = isPreview ? notification?.partnerProgramIdentifier : notification?.notificationId;

    useEffect(() => {
        if(isPreview) {
            // For Previewing Notifications
            setDetails(mapSingleNotificationFromServerToApp(notification));
        } else {
            // For Viewing Notificaitons
            if (!!notification) {
                setLoading(true);
                if (!!providerId) {
                    //Get Provider Notification by ID
                    NotificationsApi.getProviderNotificationById(notification.notificationId, providerId)
                        .then((res) => {
                            setDetails(mapSingleNotificationFromServerToApp(res.body));
                            setLoading(false);
                        })
                        .catch((error) => {
                            logger.error("Error when getting provider notification by ID", error, true);
                            setLoading(false);
                        });
                } else {
                    // Get Clinic Notification by ID
                    NotificationsApi.getNotificationById(notification.notificationId, clinicId)
                        .then((res) => {
                            setDetails(mapSingleNotificationFromServerToApp(res.body));
                            setLoading(false);
                        })
                        .catch((error) => {
                            logger.error("Error when getting clinic notification by ID", error, true);
                            setLoading(false);
                        });
                }
            }
        }
    }, [notification]);

    useEffect(() => {
        if(details?.notificationType) {
            switch (details.notificationType) {
                case LAPSED_USER_OPT_IN:
                    setOfferCloos([2443, 2444, 2445, 2446, 2447]);
                    setOfferGroups([{
                        id: 1,
                        label: "Opt-In to Lapsed User and Puppy Graduation Campaigns",
                        offerCloos: [2443, 2444, 2445, 2446, 2447],
                        offerClooMap: {},
                        selected: true,
                    }, {
                        id: 2,
                        label: "Opt-In to Lapsed User Campaign Only",
                        offerCloos: [2443, 2444, 2445, 2446],
                        offerClooMap: {},
                        selected: false,
                    }, {
                        id: 3,
                        label: "Opt-In to Puppy Graduation Campaign Only",
                        offerCloos: [2447],
                        offerClooMap: {},
                        selected: false,
                    },]);
                    setSelectedProviderId(ProviderIds.MERCK);
                    break;
                case PUPPY_GRADUATION_OPT_IN:
                    setOfferCloos([2377])
                    setSelectedProviderId(ProviderIds.MERCK);
                    break;
                case ORAVET_2022:
                    setOfferCloos([2358])
                    setSelectedProviderId(ProviderIds.BOEHRINGER_INGELHEIM);
                    break;
                case MERCK_ADOBE_ONLY:
                    if (notification.notificationId === "Merck-2023-MM-NEWLapsed") {
                        setOfferCloos([2443]);
                        setSelectedProviderId(ProviderIds.MERCK);
                    } else if (notification.notificationId === "Merck-2023-MM-NEWPUPPY") {
                        setOfferCloos([2447]);
                        setSelectedProviderId(ProviderIds.MERCK);
                    } else if (notification.notificationId === "Merck-2023-RefillReminders") {
                        setOfferCloos([3488]);
                        setSelectedProviderId(ProviderIds.MERCK);
                    } else {
                        // TODO: Update the notification UI to set CLOOs to pass in via Notification API
                        setOfferCloos([]);
                    }
                    break;
                default:
                    setOfferCloos([]);
            }
        }
    }, [details]);

    //For notifications that need to opt into offers
    const loadProgramOffers = () => {
        CouponsApi.getProgramOffers(selectedProviderId, clinicId)
            .then((res) => {
                const newOffers = flatMap(filter(res.body, o => {
                    const cloos = filter(o.offers, offer => includes(offerCloos, offer.clooId));
                    return !!cloos?.length;
                }), "offers");

                // Filter the offers so only the included ones are returned
                const filtered = filter(newOffers, o => includes(offerCloos, o.clooId));

                const updatedOffers = map(filtered, o => {
                    let libraryTitle = split(o.libraryTitle, " Brand", 2);
                    const arrayOfWords = words(snakeCase(libraryTitle[1]));
                    const species = includes(arrayOfWords, "cats") ? "Feline" : "Canine";
                    libraryTitle = split(libraryTitle[0], " Lapsed", 2);
                    return {
                        ...o,
                        libraryTitle: `${libraryTitle[0]} ${species}`
                    }
                });

                setOffers(updatedOffers);
                //Show if the user is already opt-ed into any of the offers
                const checked = [];
                let cloosChecked = [];
                let offerClooMap = {};

                forEach(newOffers, o => {
                    offerClooMap = {
                        ...offerClooMap,
                        [o.clooId]: o.ccloId,
                    };
                    if (o.activationDate) {
                        checked.push(o.ccloId);
                        cloosChecked.push(o.clooId);
                    }
                })
                setAcceptedOffers(checked);

                // OFFER GROUPS
                cloosChecked = cloosChecked.sort();
                const offerGroupsChecked = [];
                const updatedGroups = map(offerGroups||[], group => {
                    // Magic goes here....
                    if (cloosChecked.length === group.offerCloos.length) {
                        const groupOffers = group.offerCloos.sort();
                        const stringOffers = groupOffers.join(",");
                        const stringCloos = cloosChecked.join(",");

                        const isMatch = (stringOffers === stringCloos);
                        if (isMatch) {
                            // This should be an exact equal... all clooIds in the same order... I hope.
                            offerGroupsChecked.push(group.id);
                        }
                    }

                    // Mapping the cloos to add to the form
                    let clooMap = {};
                    forEach(group.offerCloos, cloo => {
                        clooMap = {
                            ...clooMap,
                            [cloo]: offerClooMap[cloo],
                        }
                    });

                    return {
                        ...group,
                        offerClooMap: clooMap,
                    }
                });
                setOfferGroups(updatedGroups);
                setSelectedOfferGroups(offerGroupsChecked);
            })
            .catch((error) => {
                handleErrorResponse("loading Merck lapsed user program offers", error);
            });
    }

    useEffect(() => {
        if (canViewClinicCouponLibrary && clinicId && offerCloos?.length && selectedProviderId) {
            loadProgramOffers();
        }
    }, [clinicId, offerCloos]);

    if (!(notificationId || isPreview)) {
        return null;
    }

    const handleClose = () => {
        if (onClose) {
            setDetails({});
            onClose(notificationId);
        }
    }

    const handleSetNotificationState = (newState, initials=null, formFieldsJson= null) => {
        let formFieldsJsonString = "{}";
        if (formFieldsJson) {
            const json = produce({
                ...details.formFieldsJson,
                ...formFieldsJson,
            }, (draft) => {
                if (draft.initials) {
                    delete draft.initials;
                }
            });

            formFieldsJsonString = JSON.stringify(json);
        }

        if (!!providerId) {
            //Update Provider notification
            setProviderNotificationState(
                notification?.notificationId,
                selectedProviderId,
                newState,
                initials,
                formFieldsJsonString,
            );
        } else {
            //Update Clinic notification
            setNotificationState(
                notification?.notificationId,
                clinicId,
                newState,
                initials,
                formFieldsJsonString,
            );
        }
    }

    const acceptAndDeclineOffers = async (offer, selectedOffers) => {
        //selectedOffers - the offers that were selected in the end
        let data = null;
        //If the offer is already accepted
        if (offer.activationDate) {
            // See if the offer is still checked
            if (!!includes(selectedOffers, offer.ccloId)) {
                // Make sure the cclo is visible/enabled for the clinic
                data = {
                    ccloId: offer.ccloId,
                    isVisibleToClinic: true,
                }
            } else {
                // Set the deactivation date and make it not visible
                data = {
                    ccloId: offer.ccloId,
                    isVisibleToClinic: false,
                    newActivationState: false
                }
            }
        } else {
            // If checked and not activated before then activate it and make it visible
            if (!!includes(selectedOffers, offer.ccloId)) {
                data = {
                    ccloId: offer.ccloId,
                    isVisibleToClinic: true,
                    newActivationState: true
                }
            }
        }

        //Only accept and decline the notification offer if there was a change
        if (!!data) {
            try {
                await CouponsApi.notificationOfferManagement(data);
                return true;
            } catch (error) {
                handleErrorResponse("opting in", error);
                return false;
            }
        } else {
            // No update needed
            return true;
        }
    }

    const handleDecline = async (initials=null, formFieldsJson=null) => {
        setLoading(true);
        let successful = true
        for(const o of offers) {
            //Stop opting into offers if there was an issue opting into one of them
            if(successful) {
                //Check if cclo was opted out of successfully
                successful = await acceptAndDeclineOffers(o, []);
            }
        }
        // Only decline if all the opting and out is successful (if applicable)
        if (successful) {
            handleSetNotificationState(DECLINED, initials, formFieldsJson);
            handleClose();
        } else {
            toast.error("There was an error when opting out of one of the offers. Please try again.");
            setLoading(false);
        }
    }

    const handleEnroll = async (initials=null, formFieldsJson=null, selectedOffers=[]) => {
        setLoading(true);
        let successful = true
        if (selectedOffers?.length){
            for(const o of offers) {
                //Stop opting into offers if there was an issue opting into one of them
                if (successful) {
                    //Check if cclo was opted into or out of successfully
                    successful = await acceptAndDeclineOffers(o, selectedOffers);
                }
            }
        }
        // Only enroll if all the opting in and out is successful (if applicable)
        if (successful) {
            handleSetNotificationState(ENROLLED, initials, formFieldsJson);
            handleClose();
        } else {
            toast.error("There was an error when opting into one of the offers. Please try again.")
            setLoading(false);
        }
    }

    const handleCancel = async (initials=null, formFieldsJson=null, selectedOffers=[]) => {
        handleSetNotificationState(CANCELED, initials, formFieldsJson);
        handleClose();
    }

    const handleDismiss = async(initials=null, formFieldsJson=null) => {
        handleSetNotificationState(ENROLLED, initials, formFieldsJson);
        handleClose();
    }

    if (loading) {
        <SpinnerTakeover show={loading}/>
    }

    if (!isPreview && !!offerCloos?.length && (offerCloos?.length !== offers?.length)) {
        // Show this if some required offers are missing
        return (
            <div>
                Your clinic does not have access to the correct coupon offers. <br/>
                Please contact Greenline Support via the chat button at the bottom of the page or<br/>
                call <GreenlineSupportPhoneNumber clinicId={clinicId}/> for help resolving this issue.
            </div>
        );
    }

    if (!!notification?.notificationType) {
        if (!details.notificationId) {
            return <SpinnerTakeover show />
        }

        switch (notification.notificationType) {
            case BI_GAMES:
                return (
                    //This is the form used to accepting and declining BI Games enrollment (Greenline Games)
                    <BiGamesForm
                        notificationDetails={details}
                        enrollmentState={notification.enrollmentState}
                        onEnroll={handleEnroll}
                        onDecline={handleDecline}
                        onCancel={handleClose}
                        onDismiss={handleDismiss}
                        isPreview={isPreview}
                    />
                );
            case BI_IR_FORM:
                //This is the form used to accepting and declining BI Instant Rebate Program
                return (
                    <BiIRForm
                        notificationDetails={details}
                        enrollmentState={notification.enrollmentState}
                        onEnroll={handleEnroll}
                        onDecline={handleDecline}
                        onCancel={handleClose}
                        onDismiss={handleDismiss}
                        isPreview={isPreview}
                    />
                );
            case GREENLINE_GIVING:
                //This is the form used to show interest in Greenline Giving - VHF (Veterinary Hope Foundation)
                return (
                    <GreenlineGivingForm
                        notificationDetails={details}
                        enrollmentState={notification.enrollmentState}
                        onEnroll={handleEnroll}
                        onDecline={handleDecline}
                        onCancel={handleClose}
                        onDismiss={handleDismiss}
                        isPreview={isPreview}
                    />
                );
            case VETCHECK:
                return (
                    <VetCheckOfferForm
                        notificationDetails={details}
                        enrollmentState={notification.enrollmentState}
                        onEnroll={handleEnroll}
                        onDecline={handleDecline}
                        onDismiss={handleDismiss}
                        isPreview={isPreview}
                    />
                );

            case LAPSED_USER_OPT_IN:
            case PUPPY_GRADUATION_OPT_IN:
            case MERCK_ADOBE_ONLY:
            case BI_ET_NON_CC_OPTIN:
                return (
                    <LapsedUserOptIn
                        notification={notification}
                        notificationDetails={details}
                        clinicId={clinicId}
                        enrollmentState={notification.enrollmentState}
                        onSubmit={handleEnroll}
                        onDecline={handleDecline}
                        onDismiss={handleDismiss}
                        canSelectOffers={(notification.notificationType === LAPSED_USER_OPT_IN)}
                        offers={offers}
                        offerGroups={offerGroups}
                        selectedOfferGroups={selectedOfferGroups}
                        acceptedOffers={acceptedOffers}
                        isPreview={isPreview}
                    />
                );
            case ADOBE_ONLY:
                return (
                    <AdobeOnly
                        notification={notification}
                        notificationDetails={details}
                        clinicId={clinicId}
                        enrollmentState={notification.enrollmentState}
                        onSubmit={handleEnroll}
                        onDecline={handleDecline}
                        onDismiss={handleDismiss}
                        isPreview={isPreview}
                    />
                );
            case CLINIC_INFORMATION_UPDATE:
                return (
                    <ClinicInformationUpdate
                        notification={notification}
                        notificationDetails={details}
                        onEnroll={handleEnroll}
                        onDismiss={handleDismiss}
                        isPreview={isPreview}
                        clinicId={clinicId}
                    />
                )

            case ZOETIS_PARASITICIDE_PILOT:
                return (
                    <ZoetisOfferForm
                        notificationDetails={details}
                        enrollmentState={notification.enrollmentState}
                        onEnroll={handleEnroll}
                        onDecline={handleDecline}
                        onDismiss={handleDismiss}
                        isPreview={isPreview}
                    />
                )

            case GAMES_SURVEY:
                return (
                    <SurveyNotification
                        notificationDetails={details}
                        enrollmentState={notification.enrollmentState}
                        onEnroll={handleEnroll}
                        onDecline={handleDecline}
                        onDismiss={handleDismiss}
                        isPreview={isPreview}
                    />
                );
        }
    }

    if (notificationId || isPreview) {
        return (
            <BasicNotification
                acceptedOffers={acceptedOffers}
                // canSelectOffers={false} // TODO: Check this if the user is able to select the offers to opt into (this is based on the notification type)
                clinicId={clinicId}
                enrollmentState={notification.enrollmentState}
                initialData={details.formFieldsJson}
                isPreview={isPreview}
                notificationDetails={details}
                notificationId={notificationId}
                notificationType={notification.notificationType}
                offers={offers}
                onCancel={handleCancel}
                onClose={handleClose}
                onDecline={handleDecline}
                onDismiss={handleDismiss}
                onEnroll={handleEnroll}
                providerId={providerId}
            />
        );
    } else {
        return null;
    }
}

Notification.propTypes = {
    actionPerformed: PropTypes.bool,
    clinicId: PropTypes.number,
    canAcceptNotifications: PropTypes.bool,
    canDeclineNotifications: PropTypes.bool,
    isPreview: PropTypes.bool,
    notification: PropTypes.object,
    onClose: PropTypes.func,
    performAction: PropTypes.func,
    providerId: PropTypes.number,
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        //Permissions
        const canAcceptNotifications = userHasPermission(PermissionTypes.ACCEPT, UserPermissions.NOTIFICATIONS, userProfile);
        const canDeclineNotifications = userHasPermission(PermissionTypes.DECLINE, UserPermissions.NOTIFICATIONS, userProfile);
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        return {
            canAcceptNotifications,
            canDeclineNotifications,
            canViewClinicCouponLibrary,
        }
    },
    (dispatch) => ({
        dismissNotification: (id) => dispatch(NotificationActions.dismissNotification(id)),
        setNotificationState: (notificationId, clinicId, newState, userInitials, formFieldsJson) => dispatch(NotificationActions.setNotificationState(notificationId, clinicId, newState, userInitials, formFieldsJson)),
        setProviderNotificationState: (notificationId, providerId, newState, userInitials, formFieldsJson) => dispatch(NotificationActions.setProviderNotificationState(notificationId, providerId, newState, userInitials, formFieldsJson)),
    }),
)(Notification);
