import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./GenericAnimation.scss";
import filter from "lodash/filter";
import isString from "lodash/isString";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import Image from "components/common/Image";

export default function GenericAnimation(props) {
	const {
		animationDuration = null,
		duration,
		frames,
		height,
		image,
		isAnimated = false,
		isTransparent = false,
		lastFrame = null,
		left = "100%",
		onAnimationComplete,
		onAnimationStarted,
		onBeforeAnimationFrame,
		top,
		width,
	} = props;
	const [currentFrame, setCurrentFrame] = useState(0);

	const frameSpeeds = useMemo(() => {
		const customFrameSpeeds = sumBy(frames, f => f?.duration || 0);
		const framesWithoutDuration = filter(frames, f => !f?.duration)?.length;
		if (customFrameSpeeds > duration) {
			console.error("The total of the frame durations are longer than the whole animation", customFrameSpeeds, duration);
		}
		const defaultFrameSpeed = (duration - customFrameSpeeds) / framesWithoutDuration;
		return map(frames, frame => {
			if (frame.duration < duration) {
				return frame.duration;
			} else if (frame.duration > duration) {
				console.error("frame Duration is longer than the whole animation", frame.duration, duration);
			}
			return defaultFrameSpeed;
		});
	}, [frames]);



    const playAnimationCycle = (durationCounter=0, animationFrame=0) => {
        setTimeout(() => {
			if (!!onBeforeAnimationFrame) {
				onBeforeAnimationFrame(animationFrame);
			}
			setCurrentFrame(animationFrame);
            if (isAnimated && (!animationDuration || durationCounter < animationDuration)) {
                const nextAnimationFrame = (animationFrame + 1) === frames.length ? 0 : animationFrame + 1;
                playAnimationCycle(durationCounter + frameSpeeds[animationFrame], nextAnimationFrame);
            } else if (lastFrame) {
                // Animation is done
	            setCurrentFrame(lastFrame);
				if (!!onAnimationComplete) {
					onAnimationComplete();
				}
            } else {
				if (!!onAnimationComplete) {
					onAnimationComplete();
				}
            }
        }, frameSpeeds[animationFrame]);
    };

    useEffect(() => {
        if (isAnimated) {
            playAnimationCycle(); // Start the animation loop
	        if (!!onAnimationStarted) {
		        onAnimationStarted();
	        }
        } else {
			if (lastFrame) {
				setCurrentFrame(lastFrame);
			} else {
				setCurrentFrame(0);
			}
        }
    }, [isAnimated]);

	const frame = frames[currentFrame];

	return (
        <div
	        className={classnames(styles.root, {
	            [styles.animating]: isAnimated,
	            [styles.transparent]: isTransparent,
	        })}
	        style={{
				width,
				height,
		        left,
		        top,
	        }}
        >
	        {isString(image) ? (
				<div
					className={styles.svgContainer}
	                style={{
						top: frame?.top || "auto",
						right: frame?.right || "auto",
						bottom: frame?.bottom || "auto",
						left: frame?.left || "auto",
						height: frame?.height || "100%",
			        }}
	            >
					<Image src={image} />
				</div>
	        ) : (
				<div
					className={styles.svgContainer}
	                style={{
						top: frame?.top || "auto",
						right: frame?.right || "auto",
						bottom: frame?.bottom || "auto",
						left: frame?.left || "auto",
						height: frame?.height || "100%",
			        }}
	            >
					{image}
				</div>
	        )}
        </div>
    );
}

GenericAnimation.propTypes = {
	animationDuration: PropTypes.number, // How long should the whole animation play (Repeat animation for X seconds)
	duration: PropTypes.number.isRequired, // How long does it take for the animation to play once
	frames: PropTypes.arrayOf(PropTypes.shape({
		image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // If image is different then the default
		duration: PropTypes.number, // If duration should not be an equal part of the animation
		top: PropTypes.string,
		right: PropTypes.string,
		bottom: PropTypes.string,
		left: PropTypes.string,
	})).isRequired,
	height: PropTypes.string.isRequired,
	image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	isAnimated: PropTypes.bool,
	isTransparent: PropTypes.bool,
	lastFrame: PropTypes.number,
	left: PropTypes.string,
	onAnimationComplete: PropTypes.func,
	onAnimationStarted: PropTypes.func,
	onBeforeAnimationFrame: PropTypes.func,
	top: PropTypes.string,
	width: PropTypes.string.isRequired,
};